import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal, Upload, Button } from 'antd';
import { DID_API } from '../../../utility/API'

const { Option } = Select;
const getVendorOptions = (vendors) => {
  let children = [];
  for (let index = 0; index < vendors.length; index++) {
    children.push(<Option key={vendors[index].vid}>{vendors[index].vid}</Option>);
  }
  return children;
};

const getUsersOptions = (users) => {
  let children = [];
  for (let index = 0; index < users.length; index++) {
    children.push(<Option key={users[index].uid}>{users[index].uid}</Option>);
  }
  return children;
};


class UploadDIDModal extends Component {

  formRef = React.createRef();
  constructor(props) {
    super(props)

    this.state = {
      confirmLoading: false,
      fileList: [],
    }
  }


  onHandleFinish = values => {
    if(this.state.fileList.length === 0){
      message.error('Select the CSV file to upload');
    }else{
      this.setState({
        confirmLoading: true,
      }, ()=>{
        var payload = new FormData();
        payload.append('vid', values.vid); 
        payload.append('cost', values.cost);
        if(values.uid){
          payload.append('uid', values.uid);
        }         
        payload.append('selling_price', values.selling_price); 
        payload.append('type', values.type); 
        payload.append('status', values.status); 
        // payload.append('faculties', values.faculties); 
        if(this.state.fileList){
          this.state.fileList.forEach(file => {
            payload.append("csv_file", file)
          });
        }
        const response = DID_API().uploadDID(payload)
        response.then(response => {
          message.success(response.data.message);
          this.setState({confirmLoading: false});
          this.props.onCancel(false, true);
        }).catch(error => {
          if (error.response.data) {
            message.error(error.response.data.detail);
          }
          this.setState({confirmLoading: false});
        });
      });
    }
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  beforeUpload = (file) => {
    const is_valid_file = file.type === 'text/csv';
    if (!is_valid_file) {
      message.error('You can only upload CSV file!');
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 30;
    if (!isLt1M) {
      message.error('File must be smaller than 30MB!');
      return false;
    }
    this.setState(prevState => ({
        fileList: [file],
    }));
    return false;
  }
  onRemoveAttachment = () => {
    this.setState(prevState => ({
      fileList: [],
    }));
  }


  render() {
    const { visible, onCancel, vendors, users } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Upload CSV for DID" okText="Submit" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={() => { this.formRef.current.submit(); }}>
        <Form
          ref={this.formRef}
          name="upload_did_csv"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="Vendors" name="vid" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select Vendor" >
              {getVendorOptions(vendors)}
            </Select>
          </Form.Item>
          <Form.Item label="Cost" name="cost" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Enter cost here" />
          </Form.Item>
          <Form.Item label="User to Assign" name="uid" >
            <Select style={{ width: '100%' }} placeholder="Select User" >
              {getUsersOptions(users)}
            </Select>
          </Form.Item>
          <Form.Item label="Selling Price" name="selling_price" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Enter selling price here" />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Change Type" >
              <Option key="TollFree">Toll Free</Option>
              <Option key="LongCode">Long Code</Option>
              <Option key="ShortCode">Short Code</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Change Status" >
              <Option key="Active">Active</Option>
              <Option key="Disabled">Disabled</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Upload CSV" name="csv_file" rules={VALIDATION_RULES['required']}>
            <Upload
              accept=".csv"
              listType="picture"
              className="upload-list-inline"
              onRemove={this.onRemoveAttachment}
              fileList={this.state.fileList} 
              beforeUpload={this.beforeUpload} >
              { this.state.fileList.length === 0 && <Button><div className="ant-upload-text">Upload File</div></Button> }
            </Upload>
          </Form.Item>
        </Form>

      </Modal>
    )
  }
}

export default UploadDIDModal;