import React, { Component } from 'react'
import "antd/dist/antd.css"
import { Card, Button, Row, Col, message, Table, Statistic } from "antd"
import AppContext from '../../AppContext'
import { customStyle } from '../../../customStyle'
import { MenuKeys } from "../../../utility/constants"
import { usersAPI } from '../../../utility/API'
import AddFund from "./AddFund"
import SendTestSMS from "./SendTestSMS"
import { Line } from 'react-chartjs-2';
import { Link } from "react-router-dom";

const fundTableColumn = [
  {
    title: "Date",
    render: (text, record, index) => record.created,
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
  },
  {
    title: "Status",
    render: (text, record, index) => record.status,
  }
]

class BaseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      chartData: {},
      isAddFundModalVisible: false,
      isSendTestSMSModalVisible: false
    };
  }

  GetColumn = () => {
    return [
      {
        title: "Direction",
        render: (text, record, index) => "Outbound",
      },      
      {
        title: "Today",
        render: (text, record, index) => record.today,
      },
      {
        title: "MTD",
        render: (text, record, index) => record.MTD,
      },
      {
        title: "YTD",
        render: (text, record, index) => record.YTD,
      }
    ]
  };

  GetDeliveryTableColumn = () => {
    return [
      {
        title: "Status",
        render: (text, record, index) => record.status,
      },      
      {
        title: "Today",
        render: (text, record, index) => record.today,
      },
      {
        title: "MTD",
        render: (text, record, index) => record.MTD,
      },
      {
        title: "YTD",
        render: (text, record, index) => record.YTD,
      }
    ]
  };

  prepareDLRTableSource = (sms_usage_stats) => {
    
    const data = [];
    data.push({
      key: 'successful',
      status: sms_usage_stats.successful.status,
      today: sms_usage_stats.successful.today,
      MTD: sms_usage_stats.successful.MTD,
      YTD: sms_usage_stats.successful.YTD,
    });
    data.push({
      key: 'unsuccessful',
      status: sms_usage_stats.unsuccessful.status,
      today: sms_usage_stats.unsuccessful.today,
      MTD: sms_usage_stats.unsuccessful.MTD,
      YTD: sms_usage_stats.unsuccessful.YTD,
    });
      
    return data
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Dashboard)
      this.context.selectMenu(MenuKeys.Dashboard);
    this.getUserDetails();
  }
  
  getUserDetails = () => {
    const response = usersAPI().getUser()
    response.then(response => {
      if(Object.entries(response.data.user).length > 0){
        const x = response.data.user.sms_usage_stats.week_stats;
        let chartLabel = [];
        let datasetX = [];
        let datasetY = [];
        for(var key in x) {
          chartLabel.push(key);
          datasetX.push(x[key]['successful'])
          datasetY.push(x[key]['unsuccessful']);          
        }
        let chartData = {
          labels: chartLabel,
          datasets: [
            {
              label: "Successful",
              // fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(153,255,51,0.4)',
              borderColor: 'rgba(153,255,51,0.6)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: datasetX,
            },
            {
              label: "Unsuccessful",
              // backgroundColor: 'red',
              // borderColor: 'red',
              data: datasetY,
            }
          ],
          
        } 
        this.setState({user: response.data.user, chartData: chartData});
        // console.log(chartData);
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showAddFundModal  = (is_visible, is_updated=false) => {
    this.setState({ isAddFundModalVisible: is_visible});
    if(is_updated){
      this.getUserDetails(); 
    }
  }

  showSendTestSMSModal  = (is_visible, is_updated=false) => {
    this.setState({ isSendTestSMSModalVisible: is_visible});
    if(is_updated){
      this.getUserDetails(); 
    }
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Dashboard">  
        {
          Object.entries(this.state.user).length > 0 &&
          <Row gutter={24} >
            <Col span={8}>
              <Card bordered={false} style={{ color: '#818181', fontSize: 25 }}>
                <Statistic title={<span>Account Balance</span>} value={this.state.user.mt_messaging_cred.quota.balance} precision={6} />
                <Button type="primary" className="mr-2" onClick={() => {this.showAddFundModal(true); }}>Add Fund</Button>
                <Button type="primary" onClick={() => {this.showSendTestSMSModal(true); }}>Send Test SMS</Button>
                <AddFund visible={this.state.isAddFundModalVisible} user={this.state.user} onCancel={this.showAddFundModal}  />
                <SendTestSMS visible={this.state.isSendTestSMSModalVisible} user={this.state.user} onCancel={this.showSendTestSMSModal}  />
                <Table className="mt-3" size="small" rowKey={record => record.bid} pagination={false} 
                columns={fundTableColumn} dataSource={this.state.user.billing} bordered />
              </Card>
              <Card className="mt-2" bordered={false} style={{ color: '#818181' }}>
                <p style={{ fontSize: 25 }}>API Credentials</p>
                <p style={{ fontSize: 15 }}>The following is your <b>Username</b> and <b>Password</b> tokens.</p>
                <p style={{ fontSize: 15 }}>You will need these credentials to make requests to API.</p>
                <p style={{ fontSize: 15 }}><Link to="#">http://gateway.inboxed.co:1401/send</Link></p>
                <p style={{ fontSize: 15 }}>
                  <b>API Username</b><br /> 
                  {this.state.user.username}
                </p>
                <p style={{ fontSize: 15 }}>
                  <b>API Password</b><br /> 
                  {this.state.user.password}
                </p>
              </Card>
            </Col>
            <Col span={16}>
              <Card bordered={false} style={{ color: '#818181'}}>
                <p style={{ fontSize: 25 }}>SMS Statistics</p>
                <Table rowKey={record => record.status} pagination={false} 
                columns={this.GetColumn()} dataSource={[this.state.user.sms_usage_stats.count]} size="small" bordered />
              </Card>
                  
              <Card className="mt-2" bordered={false} style={{ color: '#818181'}}>
                <p style={{ fontSize: 25 }}>Message Statistics</p>
                <Table rowKey={record => record.key} pagination={false} 
                columns={this.GetDeliveryTableColumn()} dataSource={this.prepareDLRTableSource(this.state.user.sms_usage_stats)} size="small" bordered />
              </Card>
              <Card className="mb-5" bordered={false} style={{ color: '#818181'}}>
                {/* <p style={{ fontSize: 25 }}>Message Statistics</p> */}
                <Line height={100} data={this.state.chartData} />
              </Card> 
               
            </Col>            
          </Row>
        }
      </Card>
    );
  }
}

BaseDashboard.contextType = AppContext;
export default BaseDashboard
