import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal } from 'antd';
import { DID_API } from '../../../utility/API'

const { Option } = Select;
const getUsersOptions = (users) => {
  let children = [];
  for (let index = 0; index < users.length; index++) {
    children.push(<Option key={users[index].uid}>{users[index].uid}</Option>);
  }
  return children;
};

class AssignURLModal extends Component {

  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      confirmLoading: false,
    }
  }


  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, () => {
      const response = DID_API().assignURL(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({
          confirmLoading: false,
        });
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false,});
      });

    });
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  render() {
    const { visible, onCancel, users } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Assign Destination URL" okText="Submit" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={() => { this.formRef.current.submit(); }}>
        <Form
          ref={this.formRef}
          name="assign_url"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="User to Assign" name="uid" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select User" >
              {getUsersOptions(users)}
            </Select>
          </Form.Item>
          <Form.Item label="Destination URL" name="destination_url" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Enter destination URL here" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default AssignURLModal;