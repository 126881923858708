import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from 'antd';
import { HeaderBar } from "./layouts/";
import { isAuthenticated, getUserType, getUser } from '../utility/API';
import MainContent from "./MainContent";
import { Provider } from "./AppContext";
import { LeftPanel } from "./layouts/";

const { Content } = Layout;

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      'isAuthenticated': isAuthenticated(),
      'user_type': getUserType(),
      'user': getUser(),
      'collapsed': true,
      'menuKey': '',
      'subMenuKey': ''
      
    }
  }
  selectMenu = (key, subKey='') => {
    this.setState({ menuKey: key, subMenuKey: subKey });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };


  setInitialAuthFlag = (values) => {
    this.setState({ 
      'isAuthenticated': values.is_auth, 
      'user_type': values.user_type,
      'user': values.user 
    })
  }
  render() {
    return (
      <Router>
        <Provider value={{
          'selectMenu': this.selectMenu, 
          'user_type': this.state.user_type,
          'user': this.state.user,  
          'menuKey': this.state.menuKey, 
          'subMenuKey' : this.state.subMenuKey, 
          'isAuthenticated': this.state.isAuthenticated,
          'setInitialAuthFlag': this.setInitialAuthFlag }} >
          <Layout>
            <HeaderBar />
            {this.state.isAuthenticated && <LeftPanel /> }
            <Layout>
              <Content style={this.state.isAuthenticated ? { marginLeft: '250px', overflow: 'initial', height: '100vh' } : {overflow: 'initial', height: '100vh'}}>
                <MainContent />  
              </Content>
              {/* <FooterBar /> */}
            </Layout>
          </Layout>
        </Provider>
      </Router>
    );
  }
}

export default App;