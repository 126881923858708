import React, { Component } from 'react';
import { Row, Col, Card, Form, Input, Button, message } from "antd";
import { VALIDATION_RULES, USER_TYPE } from '../../utility/constants';
import { adminUsersAPI, set_cookie } from '../../utility/API'
import AppContext from '../AppContext';
import "antd/dist/antd.css";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logoFile from '../../images/logo.png'

class LoginForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      componentSize: 'large',
    };
  }
  onFinish = async values => {
    this.setState({ isLoading: true });
    const response = adminUsersAPI().login(values)
    response.then(response => {
      set_cookie('Authorization', response.data.token);
      set_cookie('UserType', response.data.user_type);
      set_cookie('user', JSON.stringify(response.data.user));
      // console.log(response.data.user)
      let data = {
        is_auth: true,
        user_type: response.data.user_type,
        user: response.data.user
      }
      this.context.setInitialAuthFlag(data);
      message.success("Login successful");
      
      if(response.data.user_type===USER_TYPE.Administrator){
        this.props.history.push('/dashboard');
      }
      else if(response.data.user_type===USER_TYPE.User){
        this.props.history.push('/account/dashboard');
      }
      
    }).catch(error => {
      if (error.response) {
        this.setState({ isLoading: false });
        if(error.response.data && error.response.data.non_field_errors){
          message.error(error.response.data.non_field_errors[0]);
        }        
      }
    });
  };

  render() {
    return (
      <Row style={{ marginTop: 150 }} type="flex"  justify="center" align="middle">
        <Col span={5}>
          <Card className="white-card">
            <div className="text-center mb-2">
              <img className='logo-img' src={logoFile} alt="SMS Panel" />
            </div>
            <Form 
              onFinish={this.onFinish} 
              size="large">
              <Form.Item name="username" rules={VALIDATION_RULES['username']}>
                <Input autoFocus prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item name="password" rules={VALIDATION_RULES['password']}>
                <Input prefix={<LockOutlined className="site-form-item-icon" icon="lock" />} type="password" placeholder="Password" />
                </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-100" loading={this.state.isLoading}>
                  Sign In
                </Button>
                <span className="forgot_password">
                  <a href="/forgot_password">Forgot Password</a>
                </span>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

LoginForm.contextType = AppContext;
export default LoginForm;