import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, message, Table, Form, Button, Select, Row, Col } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, VALIDATION_RULES, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { DID_API, vendorsAPI, adminUsersAPI } from '../../../utility/API'
import UploadDIDModal from './UploadDID'
import AssignDIDModal from './AssignDID'
import AssignURLModal from './AssignURL'
import DeleteDIDModal from './DeleteDIDs'


const { Option } = Select;

const getVendorOptions = (vendors) => {
  let children = [<Option key="All">ALL</Option>];
  for (let index = 0; index < vendors.length; index++) {
    children.push(<Option key={vendors[index].vid}>{vendors[index].vid}</Option>);
  }
  return children;
};

class BaseDID extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateModalVisible: false,
      isAssignModalVisible: false,
      isAssignURLModalVisible: false,
      isDeleteDIDModalVisible: false,
      confirmLoading: false,
      vendors: [],
      users: [],
      dataSource: [],
      vid: 'All',
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
  }

  columnSource = [
    // {
    //   title: '#',
    //   render: (text, record, index) => this.state.pagination.page + index,
    // },
    // {
    //   title: "DID",
    //   dataIndex: "did"
    // },
    {
      title: "Created",
      dataIndex: "created"
    },
    {
      title: "Type",
      dataIndex: "type"
    },
    {
      title: "uid",
      dataIndex: "uid"
    },
    {
      title: "Vendor ID",
      dataIndex: "vid"
    },
    {
      title: "Connector",
      dataIndex: "cid"
    },
    {
      title: "Number",
      dataIndex: "number"
    },
    {
      title: "Cost",
      dataIndex: "cost"
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price"
    },
    {
      title: "Destination URL",
      dataIndex: "destination_url"
    },
    {
      title: "Status At",
      dataIndex: "modified"
    },
    {
      title: "Status",
      dataIndex: "status"
    },
  
  ]

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.DID){
      this.context.selectMenu(MenuKeys.DID);
    }
    
    if (this.state.vendors.length <= 0) {
      this.getAllVendors();
    }
    if (this.state.users.length <= 0) {
      this.getAllUser();
    }
    this.getDIDsData()
  };

  getAllVendors = () => {
    const response = vendorsAPI().getAll()
    response.then(response => {
      if (response.data.vendors.length > 0) {
        this.setState({ vendors: response.data.vendors});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  getAllUser = () => {
    const response = adminUsersAPI().getAll()
    response.then(response => {
      if (response.data.users.length > 0) {
        this.setState({ users: response.data.users });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  onHandleFinish = fieldsValue => {
    this.setState({
      pagination: { ...this.state.pagination, page: 1 },
      vid: fieldsValue['vid']
    }, ()=>{
      this.getDIDsData()      
    });
  };

  getDIDsData = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'vid': this.state.vid,
      }
      const response = DID_API().getDIDsByVender(values, this.state.pagination.page, this.state.pagination.pageSize)
      response.then(response => {
        if (response.data.DID_DATA.length > 0) {
          // console.log(response.data)
          this.setState({ 
            dataSource: response.data.DID_DATA,
            pagination: { ...this.state.pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false });
        } else {
          message.error('No records found!');
          this.setState({ dataSource: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          this.setState({ dataSource: [], confirmLoading: false });
          message.error('Request Failed');
        }
      });
    });
  };

  showCreateModal = (is_visible, is_updated = false) => {
    this.setState({ isCreateModalVisible: is_visible });
    if (is_updated) {
      this.getDIDsData()
    }
  }

  showAssignModal = (is_visible, is_updated = false) => {
    this.setState({ isAssignModalVisible: is_visible });
    if (is_updated) {
      this.getDIDsData()
    }    
  }

  showDeleteDIDModal = (is_visible, is_updated = false) => {
    this.setState({ isDeleteDIDModalVisible: is_visible });
    if (is_updated) {
      this.getDIDsData()
    }    
  }

  showAssignURLModal = (is_visible, is_updated = false) => {
    this.setState({ isAssignURLModalVisible: is_visible });
    if (is_updated) {
      this.getDIDsData()
    }    
  }

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getDIDsData()      
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card mb-5" bordered={false} headStyle={customStyle.headStyle} title="DID Details"
        extra={
          <>
            <Button className="theme-submit-btn mr-1" onClick={() => { this.showCreateModal(true); }} title="Upload DID CSV">
              Upload a File
            </Button>
            <Button className="theme-submit-btn mr-1" onClick={() => { this.showAssignModal(true); }} title="Assign DID to User">
              Assign DID to User
            </Button>
            <Button className="theme-submit-btn mr-1" onClick={() => { this.showAssignURLModal(true); }} title="Assign URL to User">
              Add Destination URL
            </Button>
            <Button className="theme-submit-btn mr-1" onClick={() => { this.showDeleteDIDModal(true); }} title="Delete DID by CSV">
              Delete DIDs
            </Button>
          </>
        }>
        <Card className="mb-2 card-body" bordered={false} >
          <Form
            name="vender_report"
            initialValues={{ vid: this.state.vid }}
            onFinish={this.onHandleFinish}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Vendors" name="vid" rules={VALIDATION_RULES['required']}>
                  <Select style={{ width: '100%' }} placeholder="Select Vendor" >
                    {getVendorOptions(this.state.vendors)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button loading={this.state.confirmLoading}  className="ml-2" type="primary" htmlType="submit">
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <UploadDIDModal 
          vendors={this.state.vendors} 
          users={this.state.users}
          visible={this.state.isCreateModalVisible} 
          onCancel={this.showCreateModal} />
        <AssignDIDModal 
          users={this.state.users}
          visible={this.state.isAssignModalVisible} 
          onCancel={this.showAssignModal} />
        <AssignURLModal 
          users={this.state.users}
          visible={this.state.isAssignURLModalVisible} 
          onCancel={this.showAssignURLModal} 
        />
        <DeleteDIDModal 
          visible={this.state.isDeleteDIDModalVisible} 
          onCancel={this.showDeleteDIDModal} 
        />
        
        {
          this.state.dataSource.length > 0 &&
          <Table 
          rowKey={record => record.did} 
          columns={this.columnSource} 
          dataSource={this.state.dataSource} 
          pagination={{ 
            position: 'both',
            size: 'large',
            showQuickJumper: true,
            showSizeChanger: true,
            onChange:this.onShowSizeChange,
            onShowSizeChange: this.onShowSizeChange,
            total: this.state.pagination.total,
            showTotal: this.showTotal,
            defaultPageSize: this.state.pagination.pageSize,
            itemRender:this.itemRender,
          }}
          bordered />
        }
      </Card>
    );
  }
}

BaseDID.contextType = AppContext;
export default BaseDID
