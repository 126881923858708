import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AppContext from '../AppContext'

class RequireAuth extends Component {
  static contextType = AppContext
  render() {
    const { component:WrappedComponent, ...rest } = this.props
    return (
      <Route {...rest} render={(props) => (
        this.context.isAuthenticated
          ? <WrappedComponent {...props} />
          : <Redirect to="login" />
      )} />
    )
  }
}

export default RequireAuth
