import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../../utility/constants';
import { Form, Button, Input } from 'antd';

class StepTwo extends Component {

  formRef = React.createRef();

  onHandleFinish = values => {
    this.props.handleConfirmButton(values);
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  storeValues = () => {
    const values = this.formRef.current.getFieldsValue();
    this.props.submittedValues(values);
    this.props.handleBackButton();
  }
  render() {
    return (
      <Form
        initialValues={{ 
          cid: this.props.cid, 
          host: this.props.host, 
          port: this.props.port,
          username: this.props.username,
          password: this.props.password, 
        }} 
        ref={this.formRef}
        name="step_two"
        onFinish={this.onHandleFinish}
        onFinishFailed={this.onFinishFailed}
        layout="vertical"
      >
        <Form.Item label="CID" name="cid" rules={VALIDATION_RULES['cid']}>
          <Input className="Input" placeholder="Type CID" />
        </Form.Item>
        <Form.Item label="Host" name="host" rules={VALIDATION_RULES['host']}>
          <Input className="Input" placeholder="Host Name" />
        </Form.Item>
        <Form.Item label="Port No" name="port" rules={VALIDATION_RULES['port']}>
          <Input className="Input" placeholder="Port No" />
        </Form.Item>
        <Form.Item label="Username" name="username" rules={VALIDATION_RULES['username']}>
          <Input className="Input" placeholder="Username" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={VALIDATION_RULES['password']}>
          <Input type="input" className="Input" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button className="mr-1" loading={this.props.confirmLoading} type="primary" htmlType="submit">
            Confirm
          </Button>
          <Button type="default" onClick={this.storeValues} >
            Back
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StepTwo;