import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../../utility/constants';
import { Form, Input, Button, Select } from 'antd';


const { Option } = Select;
const getCountryCodeOptions = (country_codes) => {
  let children = [];
  for (let index = 0; index < country_codes.length; index++) {
    const element = country_codes[index];
    children.push(<Option key={element.code}>{element.name}</Option>);
  }
  return children;
};

class StepOne extends Component {

  onHandleFinish = values => {
    this.props.submittedValues(values);
    this.props.handleNextButton();
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  render() {
    const { country_codes } = this.props;
    return (
      <Form initialValues={{ 
        vid: this.props.vid, 
        vendor_name: this.props.vendor_name, 
        default_country_code: this.props.default_country_code,
        default_route_rate: this.props.default_route_rate,
         
      }} 
        name="step_one" onFinish={this.onHandleFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
        <Form.Item label="Vendor ID" name="vid" rules={VALIDATION_RULES['vid']}>
          <Input className="Input" placeholder="Type VID" />
        </Form.Item>
        <Form.Item label="Nick Name" name="vendor_name" rules={VALIDATION_RULES['nick_name']}>
          <Input className="Input" placeholder="Nick Name" />
        </Form.Item>
        <Form.Item label="Default Route Country Code" name="default_country_code" rules={VALIDATION_RULES['required']}>
          <Select showSearch style={{ width: '100%' }} placeholder="Select Country Code" >
            {country_codes && getCountryCodeOptions(country_codes)}
          </Select>
        </Form.Item>
        <Form.Item label="Default Message Rate" name="default_route_rate" rules={VALIDATION_RULES['required']}>
          <Input className="Input" placeholder="Rate" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StepOne;