import React, { Component } from 'react';
import { adminUsersAPI, groupsAPI, vendorsAPI } from '../../../../utility/API';
import StepOne from './FormStepOne';
import StepTwo from './FormStepTwo';
import { message, Modal } from 'antd';

class CreateUserModal extends Component {
  state = {
    step: 1,
    step_one_fields: {
      username: '',
      password: '',
      gid: ''
    },
    step_two_fields: {
      default_route: { uuid: '', rate: ''},
      available_routes: [],
    },
    show_final_values: false,
    groups: [],
    rate_cards: [],
    confirmLoading: false
  }

  handleNextButton = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  handleBackButton = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 })
  }

  handleConfirmButton = (values) => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const { step_one_fields } = this.state;
      const payload = { ...step_one_fields, ...values }
      // console.log(payload)
      const response = adminUsersAPI().create(payload)
      response.then(response => {
        message.success(response.data.message);
        this.setState({
          confirmLoading: false,
        });
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({
            confirmLoading: false,
          });
        }
      });
    });
  }

  getStepOneValue = (values) => {
    const { step_one_fields } = this.state;
    // console.log(values);
    this.setState({
      step_one_fields: {
        ...step_one_fields,
        ...values
      }
    })
  }

  getStepTwoValue = (values) => {
    // console.log(values)
    const { step_two_fields } = this.state;
    this.setState({
      step_two_fields: {
        ...step_two_fields,
        ...values
      }, 
      rate_cards: values.dataSource
    })
  }

  componentDidMount() {
    const response = groupsAPI().getAll()
    response.then(response => {
      if (response.data.groups.length > 0) {
        this.setState({ groups: response.data.groups });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        // message.error('Request Failed');
      }
    });

    vendorsAPI().getRateCards().then(response => {
      if (response.data.rate_cards.length > 0) {
        this.setState({ rate_cards: response.data.rate_cards });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        // message.error('Request Failed');
      }
    });
  }

  render() {
    const { step, step_one_fields, step_two_fields } = this.state;
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible}  cancelButtonProps={{ style: { display: 'none' } }} title="Create a new user" okText="Close" onCancel={() => {onCancel(false);}}   onOk={() => {onCancel(false);}}>
        { 
          step === 1 ?
          <div>
            <h2> User Details </h2>
            <StepOne {...step_one_fields} groups={this.state.groups} handleNextButton={this.handleNextButton} submittedValues={this.getStepOneValue} />
          </div>
          :<div>
            <h2> Route Details </h2>
            <StepTwo {...step_two_fields} confirmLoading={this.state.confirmLoading} rate_cards={this.state.rate_cards} handleConfirmButton={this.handleConfirmButton} handleBackButton={this.handleBackButton} submittedValues={this.getStepTwoValue} />
          </div>
        }
      </Modal>
    )
  }
  
}

export default CreateUserModal;
