import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, message, Table, Button, Avatar } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { DID_API } from '../../../utility/API'


class BaseDID extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
  }

  columnSource = [
    {
      title: "Number",
      dataIndex: "number"
    },
    {
      title: "uid",
      dataIndex: "uid"
    },
    {
      title: "Cost",
      dataIndex: "selling_price"
    },
    {
      title: "Destination URL",
      dataIndex: "destination_url"
    },
    {
      title: "Status At",
      dataIndex: "modified"
    },
    {
      title: "Status",
      align: "center",
      render: (i, record) => (
        <>
          {record.status === 'Active' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
            : <Avatar style={{ backgroundColor: 'grey' }} size="small"  />
          }
        </>
      )
    },

  ]


  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.DID) {
      this.context.selectMenu(MenuKeys.DID, MenuKeys.MyNumbers);
    }
    this.getDIDsData()
  };

  getDIDsData = () => {
    const values = {
      'uid': this.context.user.uid,
    }
    const response = DID_API().getDIDsByUser(values, this.state.pagination.page, this.state.pagination.pageSize)
    response.then(response => {
      if (response.data.DID_DATA.length > 0) {
        this.setState({
          dataSource: response.data.DID_DATA,
          pagination: { ...this.state.pagination, pageTotal: response.data.total_pages, total: response.data.total_records },
        });
      } else {
        message.error('No records found!');
        this.setState({ dataSource: [] });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        this.setState({ dataSource: [] });
        message.error('Request Failed');
      }
    });
  };


  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },
    }, () => {
      this.getDIDsData()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card mb-5" bordered={false} headStyle={customStyle.headStyle} title="DID Details">
        {
          this.state.dataSource.length > 0 &&
          <Table
            rowKey={record => record.did}
            columns={this.columnSource}
            dataSource={this.state.dataSource}
            pagination={{
              size: 'large',
              showQuickJumper: true,
              showSizeChanger: true,
              onChange:this.onShowSizeChange,
              onShowSizeChange: this.onShowSizeChange,
              total: this.state.pagination.total,
              showTotal: this.showTotal,
              defaultPageSize: this.state.pagination.pageSize,
              itemRender:this.itemRender,
            }}
            size="middle" bordered />
        }
      </Card>
    );
  }
}

BaseDID.contextType = AppContext;
export default BaseDID
