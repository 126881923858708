import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Modal} from 'antd';
import { adminUsersAPI } from '../../../utility/API'

class UpdateUserBalance extends Component {

  formRef = React.createRef();

  
  onHandleFinish = values => {
    const response = adminUsersAPI().updateBalance(values)
    response.then(response => {
      message.success(response.data.message);
      this.props.onCancel(false, {}, true);
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });

  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  onCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, onCancel } = this.props;
    let { user } = this.props;
    return (
      <Modal visible={visible} title="Add User Balance" okText="Update" cancelText="Cancel" onCancel={() => {onCancel(false, {});}}  onOk={this.onOk}>
        {Object.entries(user).length !== 0 ?
          <Form
            initialValues={{ s: "recharge", balance: user.mt_messaging_cred.quota.balance, uid: user.uid }}
            ref={this.formRef}
            name="update_user_balance"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item hidden={true} name="uid" >
              <Input className="Input" />
            </Form.Item>
            <Form.Item label="Balance" name="balance" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Quota Balance" />
          </Form.Item>
            
          </Form>
          : null
        }
      </Modal>
    )
  }
}

export default UpdateUserBalance