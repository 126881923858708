import React, { Component } from 'react';
import { Form, Button, Table, Radio, InputNumber, message} from 'antd';


class StepTwo extends Component {
  
  constructor(props) {
    super(props)
  
    this.state = {
       available_routes: [],
       selected_routes: [],
       default_route: { uuid: '', rate: ''},
       dataSource: []
    }
  }
  
  columns = [
    {
      title: 'Vendor',
      dataIndex: 'vid',
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      editable: true,
      render: (text, record) => <InputNumber 
        min={text} 
        defaultValue={record.rate} 
        disabled={this.props.available_routes.findIndex(item => record.uuid === item.uuid) >= 0 || this.props.default_route.uuid === record.uuid ? true :false} 
        onChange={(event) => { this.handleRateChange(event, record); }} 
        size="small" />
    },
    {
      title: 'Default',
      render: (text, record) => <Radio
      checked={this.props.default_route.uuid === record.uuid}
      onChange={() => { this.handleDefaultRouteChange(record); }} 
      value={record.uuid}
      size="small"
      name="default_route"
    />,
    },
  ];
  
  handleRateChange = (event, row) => {
    let data = [];
    this.props.rate_cards.forEach(item => {
      if(row.uuid === item.uuid){
        item.rate = event;
        data.push(item)
      }else{
        data.push(item)
      }
    });
    this.props.submittedValues({
      default_route: {...this.props.default_route}, 
      available_routes: this.props.available_routes,
      dataSource: data
    }); 
  }

  handleDefaultRouteChange = (record) => {
    if(this.props.available_routes.findIndex(item => record.uuid === item.uuid) < 0){
      // console.log(record.cid, record.rate)
      this.props.submittedValues({
        default_route: { uuid: record.uuid, rate: record.rate}, 
        available_routes: this.props.available_routes,
        dataSource: this.props.rate_cards
      });
    }else{
      message.error('Default route can not be same as available routes');
    }
  }

 
  onHandleFinish = values => {
      if(this.props.default_route.uuid === ''){
        message.error('Select the default route');
      }else{
        this.props.handleConfirmButton({
          default_route: this.props.default_route, 
          available_routes: this.props.available_routes
        });
      } 
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };


  onSelectAvailableChange = (selectedRowKeys, rowItem) => {
    if(!selectedRowKeys.includes(this.props.default_route.uuid)){
      let data = [];
      rowItem.forEach(row => {
        if(row.uuid !== this.props.default_route.uuid){
          data.push({uuid: row.uuid, rate: row.rate})
        }
      });
      this.props.submittedValues({
        default_route: { ...this.props.default_route }, 
        available_routes: data,
        dataSource: this.props.rate_cards
      });
    }else{
      message.error('Default route can not be same as available routes');
    }
  }

  render() {
    const { rate_cards, available_routes } = this.props;
    let selected_routes = [];
    available_routes.forEach(row => {
      selected_routes.push(row.uuid)
    });
    const rowSelection = {
      selectedRowKeys: selected_routes,
      onChange: this.onSelectAvailableChange,
    };

    // console.log((this.props.available_routes.findIndex(item => 'TEST' === item.cid) >= 0) || this.props.default_route.cid === 'TEST')
    return (
      <Form name="step_two" ref={this.formRef} onFinish={this.onHandleFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
        <Table
          rowKey={record => record.uuid}
          hideDefaultSelections={true}
          rowSelection={rowSelection}
          rowClassName={() => 'editable-row'}
          columns={this.columns}
          dataSource={rate_cards}
        />
        <Form.Item >
          <Button className="mr-1" loading={this.props.confirmLoading} type="primary" htmlType="submit">
            Confirm
          </Button>
          <Button type="default" onClick={this.props.handleBackButton} >
            Back
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StepTwo;