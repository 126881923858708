import React, { Component } from 'react'
import { VALIDATION_RULES } from '../../../utility/constants'
import { Form, Input, message, Modal} from 'antd'
import { usersAPI } from '../../../utility/API'

class AddFund extends Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)
  
    this.state = {
      confirmLoading: false
    }
  }
  
  
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = usersAPI().addFund(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({
          confirmLoading: false
        });
        this.props.onCancel(false, {}, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({
          confirmLoading: false
        });
      });
    });

  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  onCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, onCancel } = this.props;
    let { user } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Add Fund to User" okText="Add" cancelText="Cancel" onCancel={() => {onCancel(false, {});}}  onOk={this.onOk}>
        {Object.entries(user).length !== 0 &&
          <Form
            initialValues={{ s: "add_fund", uid: user.uid }}
            ref={this.formRef}
            name="add_user_fund"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item hidden={true} name="uid" >
              <Input className="Input" />
            </Form.Item>
            <Form.Item label="Balance" name="balance" rules={VALIDATION_RULES['required']}>
              <Input className="Input" placeholder="Add fund here" />
            </Form.Item>
            
          </Form>
        }
      </Modal>
    )
  }
}

export default AddFund