import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Modal } from 'antd';
import { SurchargeRateAPI } from '../../../utility/API'

class EditSurchareRateModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false
    };
    this.formRef = React.createRef();
  }

  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, () => {
      const response = SurchargeRateAPI().update(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({ confirmLoading: false, });
        this.props.onCancel(false, {}, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ confirmLoading: false, });
        }
      });
    });
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }


  render() {
    const { visible, onCancel } = this.props;
    let { surcharge } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Change Surcharge Rate" okText="Change" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={this.onOk}>
        {Object.entries(surcharge).length !== 0 ?
          <Form
            initialValues={{ uuid: surcharge.uuid, surcharge_rate: surcharge.surcharge_rate }}
            ref={this.formRef}
            name="update_user_status"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item  name="uuid" hidden >
              <Input className="Input" defaultValue={surcharge.uuid} readOnly={true} />
            </Form.Item>
            <Form.Item label="Carrier Name" name="carrier_name" >
              <Input className="Input" defaultValue={surcharge.carrier_name} readOnly={true} />
            </Form.Item>
            <Form.Item label="Rate" name="surcharge_rate" rules={VALIDATION_RULES['required']}>
              <Input className="Input" placeholder="Enter surcharge rate here" />
            </Form.Item>
          </Form>
          : null
        }
      </Modal>
    )
  }
}

export default EditSurchareRateModal