import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Row, Col, Card, Form, Input, Button, message } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VALIDATION_RULES } from '../../utility/constants';


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    };
  }
  handleSubmit = values => {
    this.setState({ isLoading: true });
    values.login_id_type_code = 'EMAIL';
    // reset_password(this, values);
  };
  onSuccess = (response) => {
    this.setState({ isLoading: false });
    this.props.history.push('/login');
    message.success(response.message);
  }
  onError = (response) => {
    this.setState({ isLoading: false });
    message.error(response.message);
  }
  render() {
    return (
      <Row style={{ marginTop: 150 }} type="flex" justify="center" align="middle">
        <Col span={5}>
          <Card className="white-card">
            <h5 className="login-title mb-2">Forgot Password </h5>
            <Form onFinish={this.handleSubmit} className="login-form mt-3">
              <Form.Item name="email" rules={VALIDATION_RULES['email']}>
                <Input prefix={<FontAwesomeIcon className="login_field_icon_color" icon="envelope" />} placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button w100" loading={this.state.isLoading}>
                  RESET PASSWORD
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default ForgotPassword;