import React, { Component } from 'react';
import { VALIDATION_RULES, FILTER_TYPES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal } from 'antd';
import { filtersAPI } from '../../../utility/API';

const { Option } = Select;
class CreateFilterModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      confirmLoading: false
    };
    this.formRef = React.createRef();
  }
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = filtersAPI().create(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false});
      });
    });
  };
  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };
  
  onOk = () => {
    this.formRef.current.submit();
  }
  getFilterTypeOptions = () => {
    FILTER_TYPES.map(function(name, index){
      return <Option key={ name }>{name}</Option>;
    })
  };
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Create a new filter" okText="Create" cancelText="Cancel" onCancel={() => { onCancel(false); }}  onOk={this.onOk}>
        <Form
          ref={this.formRef}
          name="login"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="Filter Type" name="type" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select Type" >
            {
              FILTER_TYPES.map(function(name, index){
                return <Option key={ name }>{name}</Option>;
              })
            }
            </Select>
          </Form.Item>
          <Form.Item label="FID Name" name="fid" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Group ID" />
          </Form.Item>
          <Form.Item label="Parameter" name="parameter" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Parameter" />
          </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default CreateFilterModal;
