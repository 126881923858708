import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form,  message, Modal, Upload, Button } from 'antd';
import { DID_API } from '../../../utility/API'


class DeleteDIDModal extends Component {

  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      confirmLoading: false,
      fileList: [],
    }
  }


  onHandleFinish = values => {
    if(this.state.fileList.length === 0){
      message.error('Select the CSV file to upload');
    }else{
      this.setState({
        confirmLoading: true,
      }, ()=>{
        var payload = new FormData();
        payload.append('s', "csv-delete"); 
        if(this.state.fileList){
          this.state.fileList.forEach(file => {
            payload.append("csv_file", file)
          });
        }

        const response = DID_API().deleteDIDByCSV(payload)
        response.then(response => {
          message.success(response.data.message);
          this.setState({confirmLoading: false,});
          this.props.onCancel(false, true);
        }).catch(error => {
          if (error.response.data) {
            message.error(error.response.data.detail);
          }
          this.setState({confirmLoading: false,});
        });
      });
    }
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  beforeUpload = (file) => {
    const is_valid_file = file.type === 'text/csv';
    if (!is_valid_file) {
      message.error('You can only upload CSV file!');
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 30;
    if (!isLt1M) {
      message.error('File must be smaller than 30MB!');
      return false;
    }
    this.setState(prevState => ({
        fileList: [file],
    }));
    return false;
  }

  onRemoveAttachment = () => {
    this.setState(prevState => ({
      fileList: [],
    }));
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Upload CSV to Delete DIDs" okText="Submit" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={() => { this.formRef.current.submit(); }}>
        <Form
          ref={this.formRef}
          name="delete_did_csv"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="Upload CSV" name="csv_file" rules={VALIDATION_RULES['required']}>
            <Upload
              accept=".csv"
              listType="picture"
              className="upload-list-inline"
              onRemove={this.onRemoveAttachment}
              fileList={this.state.fileList} 
              beforeUpload={this.beforeUpload} >
              { this.state.fileList.length === 0 && <Button><div className="ant-upload-text">Upload File</div></Button> }
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default DeleteDIDModal;