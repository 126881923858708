import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { vendorsAPI } from '../../../utility/API';
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import CreateVendorModal from "./CreateVendor/FinalForm";
import CreateRateCardModal from "./AddRateCard";
import ListRateCardModal from './ListRateCard';
import { faList, faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      vendors: [],
      isLoading: false,
      isCreateModalVisible: false,
      isListRateCardCreateModal: false,
      isCreateRateCardModalVisible: false,
      vendor_to_update: {}
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Vendor ID",
        dataIndex: 'vid',
      },
      {
        title: "Nick Name",
        dataIndex: 'nick_name',
      },
      // {
      //   title: "Status",
      //   align: "center",
      //   width: '150px',
      //   render: (i, record) => (
      //     <>
      //       {record.status === 'started' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
      //         : <Avatar style={{ backgroundColor: 'grey' }} size="small" />
      //       }
      //     </>
      //   )
      // },
      {
        title: "Action",
        align: "center",
        width: '250px',
        render: (text, record) => (
          <span>
            <ButtonGroup>
              <Button title='Add New Rate Card' onClick={() => {this.showCreateRateCardCreateModal(true, record); }}>
                <FontAwesomeIcon icon={faPlayCircle} />
              </Button>
              <Button title='Rate Cards' onClick={() => {this.showListRateCardCreateModal(true, record); }}>
                <FontAwesomeIcon icon={faList} />
              </Button>
              <Button title='Delete Vendor'>
                <Popconfirm placement="left" title='Are you sure to delete this vendor?' onConfirm={() => {this.deleteConfirm(record.vid)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
            </ButtonGroup>
          </span>
        )
      }
    ]
  };


  deleteConfirm = (vid) => {
    console.log(vid)
    const response = vendorsAPI().delete({vid:vid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.vendors];
      this.setState({ vendors: dataSource.filter(item => item.vid !== vid) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }

  

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Vendors)
      this.context.selectMenu(MenuKeys.Vendors);
    this.getAllVendors();

  }

  getAllVendors = () => {
    const response = vendorsAPI().getAll()
    response.then(response => {
      if(response.data.vendors.length > 0){
        this.setState({vendors: response.data.vendors});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });   
  };

  showVendorCreateModal = (is_visible,  is_updated=false) => {
    this.setState({ isCreateModalVisible: is_visible});
    if(is_updated){
      this.getAllVendors(); 
    }
  }
  
  showCreateRateCardCreateModal = (is_visible, record, is_updated=false) => {
    this.setState({ isCreateRateCardModalVisible: is_visible, vendor_to_update:record });
    if(is_updated){
      this.getAllVendors(); 
    }
  }

  showListRateCardCreateModal = (is_visible, record, is_updated=false) => {
    this.setState({ isListRateCardCreateModal: is_visible, vendor_to_update:record });
    if(is_updated){
      this.getAllVendors(); 
    }
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Vendors"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => { this.showVendorCreateModal(true); }} title="add New Vendor">
            Add New
          </Button>
        }>
        { this.state.isCreateModalVisible && <CreateVendorModal visible={this.state.isCreateModalVisible} onCancel={this.showVendorCreateModal} /> }
        { this.state.isCreateRateCardModalVisible && <CreateRateCardModal visible={this.state.isCreateRateCardModalVisible} vendor={this.state.vendor_to_update} onCancel={this.showCreateRateCardCreateModal} /> }
        { this.state.isListRateCardCreateModal && <ListRateCardModal visible={this.state.isListRateCardCreateModal} vendor={this.state.vendor_to_update} onCancel={this.showListRateCardCreateModal} /> }
        
        {
          this.state.vendors.length > 0 &&
          <>
            <Table 
              rowKey={record => record.vid} 
              columns={this.GetColumn()} 
              dataSource={this.state.vendors} 
              pagination={{ 
                position: 'both',
                size: 'large',
                showTotal: this.showTotal,
              }}
              
              bordered />
          </>
        }
      </Card>
    );
  }
}

BaseVendors.contextType = AppContext;
export default BaseVendors
