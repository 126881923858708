import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import { USER_TYPE } from '../utility/constants'
import AppContext from './AppContext'
import { LoginForm, ForgotPassword, UpdatePassword, Logout, RequireAuth } from './auth'
import { NotFound } from "./common"
import AdminRouter from "../components/AdminPanel/AdminRouter";
import UserRouter from "../components/UserPanel/UserRouter";



class MainContent extends Component {
  static contextType = AppContext
  render() {
    return (
      <Switch>
        <Route exact path="/"  render={() => (<Redirect to="login" />)} />
        <Route path="/login" component={LoginForm} />
        <Route path="/forgot_password"  component={ForgotPassword} />                                    
        <Route path="/update_password" component={UpdatePassword} />
        <RequireAuth exact path="/logout" component={Logout} />
        { this.context.user_type === USER_TYPE.Administrator && <AdminRouter /> }
        { this.context.user_type === USER_TYPE.User && <UserRouter /> }
        <Route component={NotFound} />
      </Switch>
    )
  }
}



export default MainContent
