import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../../utility/constants';
import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;
const getGroupOptions = (groups) => {
  let children = [];
  for (let index = 0; index < groups.length; index++) {
    let child_obj = groups[index];
    children.push(<Option key={child_obj.name}>{child_obj.name}</Option>);
  }
  return children;
};

class StepOne extends Component {

  onHandleFinish = values => {
    this.props.submittedValues(values);
    this.props.handleNextButton();
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  render() {
    const { groups} = this.props;
    return (
      <Form initialValues={{username: this.props.username, password: this.props.password, gid: this.props.gid}} name="step_one" onFinish={this.onHandleFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
        <Form.Item label="Username" name="username" rules={VALIDATION_RULES['username']}>
          <Input className="Input" placeholder="Username" />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={VALIDATION_RULES['password']}>
          <Input className="Input" placeholder="Password" />
        </Form.Item>
        <Form.Item label="Group" name="gid" rules={VALIDATION_RULES['gid']}>
          <Select style={{ width: '100%' }} placeholder="Select Group" >
            {groups && getGroupOptions(groups)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StepOne;