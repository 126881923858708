import React, { Component } from 'react';
import { connectorsAPI, metadataAPI } from '../../../../utility/API';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { message, Modal } from 'antd';

class CreateVendorModal extends Component {
  state = {
    step: 1,
    step_one_fields: {
      vid: '',
      vendor_name: '',
      default_country_code: '',
      default_route_rate: ''
    },
    step_two_fields: {
      cid: '', 
      host: '', 
      port: '',
      username: '',
      password: '', 
    },
    country_codes: {},
    confirmLoading: false
  }

  handleNextButton = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  handleBackButton = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 })
  }

  handleConfirmButton = (values) => {
    this.setState({
      confirmLoading: true,
    }, () => {
      const { step_one_fields } = this.state;
      const payload = { ...step_one_fields, ...values }
      const response = connectorsAPI().create(payload)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false});
      });
    });
  }

  getStepOneValue = (values) => {
    const { step_one_fields } = this.state;
    this.setState({
      step_one_fields: {
        ...step_one_fields,
        ...values
      }
    })
  }

  getStepTwoValue = (values) => {
    const { step_two_fields } = this.state;
    this.setState({
      step_two_fields: {
        ...step_two_fields,
        ...values
      }})
  }

  componentDidMount() {
    const response = metadataAPI().getCountryCodes()
    response.then(response => {
      if (response.data.country_codes.length > 0) {
        this.setState({ country_codes: response.data.country_codes });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
      }
    });

  }

  render() {
    const { step, step_one_fields, step_two_fields, country_codes } = this.state;
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} 
        cancelButtonProps={{ style: { display: 'none' } }} 
        title="Add new vendor" 
        okText="Close" 
        onCancel={() => { onCancel(false); }} 
        onOk={() => { onCancel(false); }}>
        {
          step === 1 ?
            <div>
              <StepOne {...step_one_fields} 
                country_codes={country_codes}
                handleNextButton={this.handleNextButton} 
                submittedValues={this.getStepOneValue} />
            </div>
            : <div>
              <StepTwo {...step_two_fields} 
                confirmLoading={this.state.confirmLoading} 
                handleConfirmButton={this.handleConfirmButton} 
                handleBackButton={this.handleBackButton} 
                submittedValues={this.getStepTwoValue} />
            </div>
        }
      </Modal>
    )
  }

}

export default CreateVendorModal;
