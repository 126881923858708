import React, { Component } from 'react'
import "antd/dist/antd.css"
import { Card, Row, Col, message, Table, Button, Avatar } from "antd"
import AppContext from '../../AppContext'
import { customStyle } from '../../../customStyle'
import { MenuKeys } from "../../../utility/constants"
import { statsAPI, ordersAPI } from '../../../utility/API'
import { Line, HorizontalBar } from 'react-chartjs-2';
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeBillingStatus from '../billing/ChangeBillingStatus'

const deliveryTableColumn = [
  {
    title: "Status",
    render: (text, record, index) => record.status,
  },
  {
    title: "Today",
    render: (text, record, index) => record.today,
  },
  {
    title: "MTD",
    render: (text, record, index) => record.MTD,
  },
  {
    title: "YTD",
    render: (text, record, index) => record.YTD,
  }
]

class BaseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      sms_log: {},
      chartData: {},
      orders: [],
      order_to_update: {},
      vendorBarChartData: {},
      isStatusModalVisible: false,
      
    };
  }

  GetColumn = () => {
    return [
      {
        title: "Direction",
        render: (text, record, index) => "Outbound",
      },
      {
        title: "Today",
        dataIndex: "today",
        // render: (text, record, index) => record.today,
      },
      {
        title: "MTD",
        dataIndex: "MTD",
        // render: (text, record, index) => record.MTD,
      },
      {
        title: "YTD",
        dataIndex: "YTD",
        // render: (text, record, index) => record.YTD,
      }
    ]
  };

  orderTableColumn = () => {
    return [
      // {
      //   title: '#',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Date",
        render: (text, record, index) => record.created,
      },
      {
        title: "uid",
        dataIndex: "uid",
      },
      {
        title: "Transaction ID",
        dataIndex: "transaction_id",
      },
      {
        title: "Amount",
        render: (text, record, index) => record.amount,
      },
      {
        title: "Status",
        align: "center",
        render: (i, record) => (
          <>
            {record.status === 'Approved' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
              : <Avatar style={{ backgroundColor: 'grey' }} size="small"  />
            }
          </>
        )
      },
      {
        title: "Action",
        align: "center",
        render: (text, record) => (<>
          {record.status !== 'Approved' &&
          <Button onClick={() => { this.showChangeStatusModal(true, record); }}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </Button>
        }
        </>)
          
      }
    ]
  }

  prepareDLRTableSource = (sms_usage_stats) => {

    const data = [];
    data.push({
      key: 'successful',
      status: sms_usage_stats.successful.status,
      today: sms_usage_stats.successful.today,
      MTD: sms_usage_stats.successful.MTD,
      YTD: sms_usage_stats.successful.YTD,
    });
    data.push({
      key: 'unsuccessful',
      status: sms_usage_stats.unsuccessful.status,
      today: sms_usage_stats.unsuccessful.today,
      MTD: sms_usage_stats.unsuccessful.MTD,
      YTD: sms_usage_stats.unsuccessful.YTD,
    });

    return data
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Dashboard)
      this.context.selectMenu(MenuKeys.Dashboard);
    this.getStatsDetails();
    this.getOrderDetails();
  }

  getStatsDetails = () => {
    const response = statsAPI().getSMSLog()
    response.then(response => {
      if (Object.entries(response.data.sms_log).length > 0) {
        const x = response.data.sms_log.week_stats;
        let chartLabel = [];
        let datasetX = [];
        let datasetY = [];
        for (var key in x) {
          chartLabel.push(key);
          datasetX.push(x[key]['successful'])
          datasetY.push(x[key]['unsuccessful']);
        }
        let chartData = {
          labels: chartLabel,
          datasets: [
            {
              label: "Successful",
              // fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(153,255,51,0.4)',
              borderColor: 'rgba(153,255,51,0.6)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: datasetX,
            },
            {
              label: "Unsuccessful",
              // backgroundColor: 'red',
              // borderColor: 'red',
              data: datasetY,
            }
          ],

        }
        
        
        
        this.setState({ sms_log: response.data.sms_log, 
        chartData: chartData, 
        });
        // console.log(chartData);
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  getOrderDetails = () => {
    const response = ordersAPI().getAll(1, 5)
    response.then(response => {
      this.setState({ orders: response.data.billings });
      
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showChangeStatusModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isStatusModalVisible: is_visible, order_to_update: record});
    if(is_updated){
      this.getOrderDetails(); 
    }
  }


  getUserChartToday = (users_stats) => {
    let chartLabel = [];
    let dataset = [];
    for (let [key, value] of Object.entries(users_stats)) {
      if(key === 'today'){
        for (let [name, item] of Object.entries(value)) {
          chartLabel.push(name)
          dataset.push(item['successful']['count'])
        }
      }
    }
    let chartData = {
      labels: chartLabel,
      datasets: [
          {
            label: 'Today',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2,   
            data: dataset
          }

        ]
    };
    return chartData;
  };

  getUserChartMTD = (users_stats) => {
    let chartLabel = [];
    let dataset = [];
    for (let [key, value] of Object.entries(users_stats)) {
      if(key === 'MTD'){
        for (let [name, item] of Object.entries(value)) {
          chartLabel.push(name)
          dataset.push(item['successful']['count'])
        }
        
      }
    }
    let chartData = {
      labels: chartLabel,
        datasets: [
          {
            label: 'MTD',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2, 
            data: dataset
          }
        ]
    };
    return chartData;
  };
  getUserChartYTD = (users_stats) => {
    let chartLabelVendor = [];
    let dataset = [];
    for (let [key, value] of Object.entries(users_stats)) {
      if(key === 'YTD'){
        for (let [name, item] of Object.entries(value)) {
          chartLabelVendor.push(name)
          dataset.push(item['successful']['count'])
        }
        
      }
    }
    let vendorBarChartData = {
      labels: chartLabelVendor,
        datasets: [
          {
            label: 'YTD',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2, 
            data: dataset
          }
        ]
    };
    return vendorBarChartData;
  };



  getConnectorChartToday = (connector_stats) => {
    let chartLabel = [];
    let dataset = [];
    for (let [key, value] of Object.entries(connector_stats)) {
      if(key === 'today'){
        for (let [name, item] of Object.entries(value)) {
          chartLabel.push(name)
          dataset.push(item['successful']['count'])
        }
        
      }
    }
    let chartData = {
      labels: chartLabel,
        datasets: [
          {
            label: 'Today',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2, 
            data: dataset
          }
        ]
    };
    return chartData;
  };
  getConnectorChartMTD = (connector_stats) => {
    let chartLabel = [];
    let dataset = [];
    for (let [key, value] of Object.entries(connector_stats)) {
      if(key === 'MTD'){
        for (let [name, item] of Object.entries(value)) {
          chartLabel.push(name)
          dataset.push(item['successful']['count'])
        }
        
      }
    }
    let chartData = {
      labels: chartLabel,
        datasets: [
          {
            label: 'MTD',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2, 
            data: dataset
          }
        ]
    };
    return chartData;
  };
  getConnectorChartYTD = (connector_stats) => {
    let chartLabel = [];
    let dataset = [];
    for (let [key, value] of Object.entries(connector_stats)) {
      if(key === 'YTD'){
        for (let [name, item] of Object.entries(value)) {
          chartLabel.push(name)
          dataset.push(item['successful']['count'])
        }
        
      }
    }
    let chartData = {
      labels: chartLabel,
        datasets: [
          {
            label: 'YTD',
            barPercentage: 0.5,
            barThickness: 10,
            maxBarThickness: 10,
            minBarLength: 2, 
            data: dataset
          }
        ]
    };
    return chartData;
  };

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Dashboard">
        {
          Object.entries(this.state.sms_log).length > 0 &&
          <Row gutter={24} >
            <Col span={8} className="mb-5">
              <Card bordered={false} style={{ color: '#818181', fontSize: 25 }}>
                <p style={{ fontSize: 20 }}>Order Details</p>
                <Table scroll={{x:600}} className="mt-2" size="small" rowKey={record => record.bid} pagination={false}
                  columns={this.orderTableColumn()} dataSource={this.state.orders} bordered />
                <ChangeBillingStatus visible={this.state.isStatusModalVisible} billing_order={this.state.order_to_update} onCancel={this.showChangeStatusModal}  /> 
              </Card>
              <Card className="mt-2" bordered={false} style={{ color: '#818181' }}>
                <p style={{ fontSize: 20 }}>SMS Statistics</p>
                <Table rowKey={record => record.status} pagination={false}
                  columns={this.GetColumn()} dataSource={[this.state.sms_log.count]} size="small" bordered />
              </Card>
              <Card className="mt-2" bordered={false} style={{ color: '#818181' }}>
                <p style={{ fontSize: 20 }}>Message Statistics</p>
                <Table rowKey={record => record.key} pagination={false}
                  columns={deliveryTableColumn} dataSource={this.prepareDLRTableSource(this.state.sms_log)} size="small" bordered />
                
              </Card>

              
            </Col>
            <Col span={16} className="mb-5">  
              <Row gutter={10} >
                <Col span={12}>          
                  <Card bordered={false} style={{ color: '#818181' }}>
                    <p style={{ fontSize: 20 }}>Vendor Total Messages</p>
                    <HorizontalBar width={100} height={30} data={this.getConnectorChartToday(this.state.sms_log.connector_stats)} />
                    <HorizontalBar className="mt-2" width={100} height={30} data={this.getConnectorChartMTD(this.state.sms_log.connector_stats)} />
                    <HorizontalBar className="mt-2" width={100} height={30} data={this.getConnectorChartYTD(this.state.sms_log.connector_stats)} />
                  </Card>
                </Col>
                <Col span={12}>          
                  <Card bordered={false} style={{ color: '#818181' }}>
                    <p style={{ fontSize: 20 }}>Users Total Messages</p>                
                    <HorizontalBar width={100} height={30} data={this.getUserChartToday(this.state.sms_log.users_stats)} />
                    <HorizontalBar className="mt-2" width={100} height={30} data={this.getUserChartMTD(this.state.sms_log.users_stats)} />
                    <HorizontalBar className="mt-2" width={100} height={30} data={this.getUserChartYTD(this.state.sms_log.users_stats)} />
                  </Card>
                </Col>
              </Row>
              <Card className="mt-2" bordered={false} style={{ color: '#818181' }}>
                <p style={{ fontSize: 20 }}>Message Statistics</p>
                <Line height={80} data={this.state.chartData} />
                
              </Card>

            </Col>
          </Row>
        }
      </Card>
    );
  }
}

BaseDashboard.contextType = AppContext;
export default BaseDashboard
