import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Modal } from 'antd';
import { groupsAPI } from '../../../utility/API'

class CreateGroupModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: {},
      confirmLoading: false
      
    };
    this.formRef = React.createRef();
  }
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = groupsAPI().create(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false,});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          this.setState({confirmLoading: false,});
          message.error('Request Failed');
        }
      });
    });  
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} destroyOnClose={true} confirmLoading={this.state.confirmLoading} title="Create a new group" okText="Create" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={this.onOk}>
        <Form
          ref={this.formRef}
          name="login"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical" 
        >
          <Form.Item label="GID" name="gid" rules={VALIDATION_RULES['gid']}>
            <Input className="Input" placeholder="Group ID" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default CreateGroupModal;
