import React, { Component } from "react";
import { Card } from "antd";
import { customStyle } from '../../customStyle';

class HomePage extends Component {
    render() {
        return (
            <React.Fragment>
                <Card className="custom-card card-body" bordered={false} title="Jasmin SMPP Server"  headStyle={customStyle.headStyle}>
                     <h1>HELLO FROM REACT COMP</h1>
                </Card>                
            </React.Fragment>
        )
    }
}

export default HomePage;

