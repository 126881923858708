import React, { Component } from 'react';
import moment from 'moment';
import "antd/dist/antd.css";
import { Card, message, Table, Form, Button, DatePicker, Select, Row, Col, Tabs } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, VALIDATION_RULES, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { statsAPI, adminUsersAPI } from '../../../utility/API'

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const getUserOptions = (users) => {
  let children = [<Option key="All">ALL</Option>];
  for (let index = 0; index < users.length; index++) {
    children.push(<Option key={users[index].uid}>{users[index].uid}</Option>);
  }
  return children;
};

const columnSource = [
  {
    title: "smpp-msgid",
    dataIndex: "smpp_msgid",
    width: "350px"
  },
  {
    title: "uid",
    dataIndex: "uid"
  },
  {
    title: "Carrier",
    dataIndex: "carrier_name"
  },
  {
    title: "To Number",
    dataIndex: "destination_addr"
  },
  {
    title: "Surcharge Amount",
    dataIndex: "amount"
  },
  {
    title: "Created",
    dataIndex: "created"
  }
]


const statsColumnSource = [
  {
    title: "uid",
    dataIndex: "uid"
  },
  {
    title: "Date",
    dataIndex: "day"
  },
  {
    title: "SMS Count",
    dataIndex: "sms_count"
  },
  {
    title: "Surcharge Amount",
    dataIndex: "sum_amount"
  }
]

class BaseSurchargeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surcharge_details: [],
      surcharge_stats: [],
      users: [],
      uid: 'All',
      start_date: moment().subtract(7, 'day'),
      end_date: moment(),
      confirmLoading: false,
      loading: false,
      log_pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
      stats_pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.SurchargeReport)
      this.context.selectMenu(MenuKeys.SurchargeReport, MenuKeys.Sub1);
    if(this.state.users.length <= 0){
      this.getAllUser();
      this.getSurchargeSMSLogs()
      this.getSurchargeStats()
    }  
  };

  getAllUser = () => {
    const response = adminUsersAPI().getAll()
    response.then(response => {
      if(response.data.users.length > 0){
        this.setState({users: response.data.users, mounted: true});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  getSurchargeSMSLogs = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'uid': this.state.uid,
        'start_date': this.state.start_date.format('YYYY-MM-DD'),
        'end_date': this.state.end_date.format('YYYY-MM-DD') 
      }
      const response = statsAPI().getSurchargeLogDetails(values, this.state.log_pagination.page, this.state.log_pagination.pageSize)
      response.then(response => {
        if (response.data.surcharge_details.length > 0) {
          this.setState({ 
            surcharge_details: response.data.surcharge_details,
            log_pagination: { ...this.state.log_pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false
          });
        } else {
          message.error('No records found!');
          this.setState({ surcharge_details: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ surcharge_details: [], confirmLoading: false });
        }
      });
    });
  };

  getSurchargeStats = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'uid': this.state.uid,
        'start_date': this.state.start_date.format('YYYY-MM-DD'),
        'end_date': this.state.end_date.format('YYYY-MM-DD') 
      }
      const response = statsAPI().getSurchargeStats(values, this.state.stats_pagination.page, this.state.stats_pagination.pageSize)
      response.then(response => {
        if (response.data.surcharge_stats.length > 0) {
          this.setState({ 
            surcharge_stats: response.data.surcharge_stats,
            stats_pagination: { ...this.state.stats_pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false
          });
        } else {
          message.error('No records found!');
          this.setState({ surcharge_stats: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ surcharge_stats: [], confirmLoading: false });
        }
      });
    });
  };

  onHandleFinish = fieldsValue => {
    // const rangeValue = fieldsValue['dateRange'];
    // this.setState({
    //   pagination: { ...this.state.pagination, page: 1 },
    //   uid: fieldsValue['uid'], start_date: rangeValue[0], end_date: rangeValue[1]
    // }, ()=>{
    //   this.getSMSLogs()      
    // });
  };

  onSubmitClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        log_pagination: { ...this.state.log_pagination, page: 1 },
        stats_pagination: { ...this.state.stats_pagination, page: 1 },
        uid: values['uid'], start_date: rangeValue[0], end_date: rangeValue[1]
      }, ()=>{
        this.getSurchargeSMSLogs()
        this.getSurchargeStats()
      });
    }).catch(errorInfo => {
        
    });
  };
  
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      log_pagination: { ...this.state.log_pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getSurchargeSMSLogs()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Surcharge Report">
        <Card className="mb-2 card-body" bordered={false} >
          <Form
            ref={this.formRef}
            name="user_report"
            initialValues={{ uid: this.state.uid, dateRange: [this.state.start_date, this.state.end_date] }}
            onFinish={this.onHandleFinish}
          >
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="Users" name="uid" rules={VALIDATION_RULES['required']}>
                  <Select style={{ width: '100%' }} placeholder="Select User" >
                    {getUserOptions(this.state.users)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Date Range" name="dateRange" rules={VALIDATION_RULES['required']}>
                  <RangePicker style={{ width: '100%' }}  ranges={{
                      'Yesterday': [moment().subtract(1, 'day'), moment()],
                      '30 Days': [moment().subtract(1, 'month').add(1, 'day'), moment()],
                      '60 Days': [moment().subtract(2, 'month').add(1, 'day'), moment()],
                      '90 Days': [moment().subtract(3, 'month').add(1, 'day'), moment()],
                    }}
                   size='middle' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button loading={this.state.confirmLoading} 
                  className="ml-2" type="primary" 
                  htmlType="submit"
                  onClick={this.onSubmitClick}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Tabs defaultActiveKey="1" type="card">
            <TabPane tab="Surcharge Stats" key="1">
              { this.state.surcharge_stats.length > 0 &&
                <Table 
                  className="mb-5"
                  rowKey={record => record.uid+'_'+record.day} 
                  columns={statsColumnSource} 
                  dataSource={this.state.surcharge_stats} 
                  size="middle" 
                  bordered 
                />
              }
            </TabPane>
            <TabPane tab="Surcharge Log Details" key="2">
              { this.state.surcharge_details.length > 0 &&
                <Table 
                  className="mb-5"
                  rowKey={record => record.smpp_msgid} 
                  columns={columnSource} 
                  dataSource={this.state.surcharge_details} 
                  pagination={{ 
                    position: 'both',
                    size: 'large',
                    showQuickJumper: true,
                    showSizeChanger: true,
                    onChange:this.onShowSizeChange,
                    onShowSizeChange: this.onShowSizeChange,
                    total: this.state.log_pagination.total,
                    showTotal: this.showTotal,
                    defaultPageSize: this.state.log_pagination.pageSize,
                    itemRender:this.itemRender,
                  }}
                  size="middle" 
                  bordered 
                />
              }
              
            </TabPane>
          </Tabs>
      </Card>
    );
  }
}

BaseSurchargeReport.contextType = AppContext;
export default BaseSurchargeReport
