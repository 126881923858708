import React from 'react'
import { RequireAuth } from '../auth'
import { BaseUserContainer } from "./users"
import { BaseGroups } from "./groups"
import { BaseConnectors, BaseVendors } from "./vendors"
import { BaseMTRouters } from "./mtrouters"
import { BaseFilters } from "./routefilters"
import { UserReports, VendorReports, BaseSurchargeReport } from "./reports"
import { BaseBilling } from './billing'
import { BaseDashboard } from './dashboard'
import { BaseDID } from './DID'
import { BaseSurcharge } from './surcharge'


const AdminRouter = () => {
  return (
    <React.Fragment>
      <RequireAuth exact path="/dashboard" component={BaseDashboard} />
      <RequireAuth exact path="/vendors" component={BaseVendors} />
      <RequireAuth exact path="/connectors" component={BaseConnectors} />
      <RequireAuth exact path="/did" component={BaseDID} />
      <RequireAuth exact path="/users" component={BaseUserContainer} />
      <RequireAuth exact path="/surcharge" component={BaseSurcharge} />
      <RequireAuth exact path="/groups" component={BaseGroups} />
      <RequireAuth exact path="/filters" component={BaseFilters} />
      <RequireAuth exact path="/mtrouter" component={BaseMTRouters} />
      <RequireAuth exact path="/users/reports" component={UserReports} />
      <RequireAuth exact path="/vendors/reports" component={VendorReports} />
      <RequireAuth exact path="/surcharge/reports" component={BaseSurchargeReport} />
      <RequireAuth exact path="/orders" component={BaseBilling} />
    </React.Fragment>
  )
}

export default AdminRouter
