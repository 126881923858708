import React, { Component } from 'react';
import { userRoutesAPI, vendorsAPI } from '../../../utility/API'
import { Modal, Table, Radio, InputNumber, message} from 'antd';

class RouteRateChangeModal extends Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)  
    this.state = {
      user_routes: [],
      available_routes: [],
      selected_routes: [],
      default_route: { uuid: '', rate: ''},
      rate_cards: []
    }
  }
  
  columns = [
    {
      title: 'Vendor',
      dataIndex: 'vid',
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      editable: true,
      render: (text, record) => <InputNumber 
        min={record.rate} 
        defaultValue={record.rate} 
        disabled={this.state.available_routes.findIndex(item => record.uuid === item.uuid) >= 0 || this.state.default_route.uuid === record.uuid ? true :false} 
        onChange={(event) => { this.handleRateChange(event, record); }} 
        size="small" />
    },
    {
      title: 'Default',
      render: (text, record) => <Radio
      checked={this.state.default_route.uuid === record.uuid}
      onChange={() => { this.handleDefaultRouteChange(record); }} 
      value={record.uuid}
      size="small"
      name="default_route"
    />,
    },
  ];


  onHandleFinish = () => {
    // const response = adminUsersAPI().updateRate(values)
    // response.then(response => {
    //   message.success(response.data.message);
    //   this.props.onCancel(false, {}, true);
    // }).catch(error => {
    //   if (error.response) {
    //     console.log(error.response)
    //     message.error('Request Failed');
    //   }
    // });

  };
  
  getAllUserRoutes = (uid) => {

    vendorsAPI().getRateCards().then(response => {
      if (response.data.rate_cards.length > 0) {
        this.setState({ rate_cards: response.data.rate_cards });
        
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        // message.error('Request Failed');
      }
    });

    const response = userRoutesAPI().getUserRoutesById({uid: uid})
    response.then(response => {
      if (response.data.user_routes.length > 0) {
        this.setState({ user_routes: response.data.user_routes });

        let data = [];
        response.data.user_routes.forEach(row => {
          if(!row.is_default){
            data.push(row.rate_card_uuid)
            var rc_index = this.state.rate_cards.findIndex(item => row.rate_card_uuid === item.uuid)
              if (rc_index > 0){
                this.setState({
                  rate_cards: [
                     ...this.state.rate_cards.slice(0, rc_index),
                     { ...this.state.rate_cards[rc_index], rate: 10 },
                     ...this.state.rate_cards.slice(rc_index+1)
                  ]
                });
              }
          }else{
            this.setState({ default_route: { uuid: row.rate_card_uuid, rate: row.rate} });
          }
        });
        this.setState({ selected_routes: data });

      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  handleRateChange = (event, row) => {
    let data = [];
    this.state.user_routes.forEach(item => {
      if(row.uuid === item.uuid){
        item.rate = event;
        data.push(item)
      }else{
        data.push(item)
      }
    });
    this.setState({ user_routes: data });
  }

  handleDefaultRouteChange = (record) => {
    if(this.state.available_routes.findIndex(item => record.uuid === item.uuid) < 0){
      this.setState({ default_route: { uuid: record.uuid, rate: record.rate} });
    }else{
      message.error('Default route can not be same as available routes');
    }
  }

  onSelectAvailableChange = (selectedRowKeys, rowItem) => {
    if(!selectedRowKeys.includes(this.state.default_route.uuid)){
      console.log(rowItem);
      let data = [];
      rowItem.forEach(row => {
        if(row.uuid !== this.state.default_route.uuid){
          data.push({uuid: row.uuid, rate: row.rate})
        }
      });
      this.setState({ available_routes: data });
    }else{
      message.error('Default route can not be same as available routes');
    }
  }

  render() {
    const { visible, onCancel, user } = this.props;
    const rowSelection = {
      selectedRowKeys: this.state.selected_routes,
      onChange: this.onSelectAvailableChange,
    };
    return (
      <Modal destroyOnClose={true} size={"large"} visible={visible} title="User Routes Rate" okText="Update" cancelText="Cancel" onCancel={() => { Modal.destroyAll(); onCancel(false, {});}} onOk={this.onHandleFinish}>
        { this.state.user_routes.length === 0 && user.uid && this.getAllUserRoutes(user.uid)}
        {
          this.state.user_routes.length > 0 &&
          <Table
            rowKey={record => record.uuid}
            hideDefaultSelections={true}
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={this.state.rate_cards}
          />
        }
      </Modal>
    )
  }
}

export default RouteRateChangeModal