import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { filtersAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import CreateFilterModal from "./CreateFilter";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      isLoading: false,
      isModalVisible: false,
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Filter ID",
        dataIndex: 'fid',
      },
      {
        title: "Type",
        dataIndex: 'type',
      },
      {
        title: "Routes",
        dataIndex: 'routes',
      },
      {
        title: "Description",
        dataIndex: 'description',
      },
     
      // {
      //   title: "Status",
      //   align: "center",
      //   width: '150px',
      //   render: (i, record) => (
      //     <>
      //       {record.status === 'started' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
      //         : <Avatar style={{ backgroundColor: 'grey' }} size="small" />
      //       }
      //     </>
      //   )
      // },

      {
        title: "Action",
        align: "center",
        width: '250px',
        render: (text, record) => (
          <span>
            <ButtonGroup>
              <Button>
                <Popconfirm placement="left" title='Are you sure to delete this filter?' onConfirm={() => {this.deleteConfirm(record.fid)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
            </ButtonGroup>
          </span>
        )
      }
    ]
  };

  deleteConfirm = (fid) => {
    const response = filtersAPI().delete({fid:fid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.filters];
      this.setState({ filters: dataSource.filter(item => item.fid !== fid) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Filters)
      this.context.selectMenu(MenuKeys.Filters);
    this.getAllFilters()   
  }

  getAllFilters = () => {
    filtersAPI().getAll().then(response => {
      if(response.data.filters.length > 0){
        this.setState({filters: response.data.filters});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showModal = (is_visible, is_updated=false) => {
    this.setState({ isModalVisible: is_visible });
    if(is_updated){
      this.getAllFilters(); 
    }
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Filters"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => { this.showModal(true); }} title="add New Filter">
            Add New
          </Button>
        }>
        <CreateFilterModal visible={this.state.isModalVisible} onCancel={this.showModal} />
        {
          this.state.filters.length > 0 &&
          <Table 
            rowKey={record => record.fid} 
            columns={this.GetColumn()} 
            dataSource={this.state.filters} 
            pagination={{ 
              position: 'both',
              size: 'large',
              showSizeChanger: true,
              showTotal: this.showTotal,
            }}
            bordered />
        }
      </Card>
    );
  }
}

BaseFilters.contextType = AppContext;
export default BaseFilters
