import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal} from 'antd';
import { ordersAPI } from '../../../utility/API'

const { Option } = Select;

class ChangeBillingStatus extends Component {

  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      confirmLoading: false
    }
  }
  

  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = ordersAPI().changeStatus(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false,});
        this.props.onCancel(false, {}, true);
      }).catch(error => {
        if (error.response) {
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false,});
      });
    });
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };


  render() {
    const { visible, onCancel } = this.props;
    let { billing_order } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Change Group Status" okText="Change" cancelText="Cancel" onCancel={() => { onCancel(false, {}); }} onOk={() => { this.formRef.current.submit(); }}>
        {Object.entries(billing_order).length !== 0 &&
          <Form
            initialValues={{ s: billing_order.status, 
              bid: billing_order.bid, 
              uid: billing_order.uid,
              transaction_id: billing_order.transaction_id
            }}
            ref={this.formRef}
            name="update_user_order_status"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item  name="bid" hidden >
              <Input className="Input" defaultValue={billing_order.bid} readOnly={true} />
            </Form.Item>
            <Form.Item label="User ID" name="uid" >
              <Input className="Input" defaultValue={billing_order.uid} readOnly={true} />
            </Form.Item>
            <Form.Item label="Balance">
              <Input className="Input" defaultValue={billing_order.amount} readOnly={true} />
            </Form.Item>
            <Form.Item label="Transaction ID" name="transaction_id">
            <Input className="Input" placeholder="Type transaction here" />
            </Form.Item>
            <Form.Item label="Status" name="s" rules={VALIDATION_RULES['required']}>
              <Select style={{ width: '100%' }} placeholder="Change status" >
                <Option key="Pending">Pending</Option>
                <Option key="Approved">Approved</Option>
                <Option key="Cancelled">Cancelled</Option>
              </Select>
            </Form.Item>
          </Form>
          
        }
      </Modal>
    )
  }
}

export default ChangeBillingStatus;