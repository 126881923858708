import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { SurchargeRateAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import AddSurchargeRateModal from "./AddSurchargeRate";
import EditSurchareRateModal from './EditSurchargeRate'
import { faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseSurcharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      surcharge: [],
      isLoading: false,
      isAddModalVisible: false,
      isEditModalVisible: false,
      surcharge_record_to_update: {}
    };
  }

  GetColumn = () => {
    return [
      {
        title: "Carrier Name",
        dataIndex: 'carrier_name',
      },
      {
        title: "Surcharge Rate",
        dataIndex: 'surcharge_rate',
      },
      {
        title: "Action",
        align: "center",
        render: (text, record) => (
          <ButtonGroup>
            <Button onClick={() => {this.showEditModal(true, record); }}>
              <FontAwesomeIcon icon={faPlayCircle} />
            </Button>
            <Button>
                <Popconfirm placement="left" title='Are you sure to delete this surcharge rate?' onConfirm={() => {this.deleteConfirm(record.uuid)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
          </ButtonGroup>
        )
      }
    ]
  };

  deleteConfirm = (uuid) => {
    const response = SurchargeRateAPI().delete({uuid:uuid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.surcharge];
      this.setState({ surcharge: dataSource.filter(item => item.uuid !== uuid) });
    }).catch(error => {
      if (error.response) {
        message.error('Delete Request Failed');
      }
    });    
  }
  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Surcharge)
      this.context.selectMenu(MenuKeys.Surcharge);
    this.getAllSurchargeRates();
   
  }

  getAllSurchargeRates = () => {
    const response = SurchargeRateAPI().getAll()
    response.then(response => {
      if(response.data.surcharge.length > 0){
        this.setState({surcharge: response.data.surcharge});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showCreateModal = (is_visible, is_updated=false) => {
    this.setState({ isAddModalVisible: is_visible });
    if(is_updated){
      this.getAllSurchargeRates(); 
    }
  }

  showEditModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isEditModalVisible: is_visible, surcharge_record_to_update: record});
    if(is_updated){
      this.getAllSurchargeRates(); 
    }
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Surcharge Rate Details"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => { this.showCreateModal(true); }} title="Add New Surcharge Rate">
            Add New
          </Button>
        }>
        <AddSurchargeRateModal visible={this.state.isAddModalVisible} onCancel={this.showCreateModal} />
        {
          this.state.surcharge.length > 0 &&
          <>
            <EditSurchareRateModal visible={this.state.isEditModalVisible} surcharge={this.state.surcharge_record_to_update} onCancel={this.showEditModal}  />
            <Table 
              rowKey={record => record.uuid} 
              columns={this.GetColumn()} 
              dataSource={this.state.surcharge} 
              pagination={{ 
                position: 'both',
                size: 'large',
                showTotal: this.showTotal,
              }} 
              bordered />
          </>
        }
      </Card>
    );
  }
}

BaseSurcharge.contextType = AppContext;
export default BaseSurcharge
