import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { connectorsAPI } from '../../../utility/API';
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
// import CreateVendorModal from "./CreateVendor/FinalForm";
// import CreateConnectorModal from "./CreateConnector";

import UpdateConnector from './UpdateConnector'
import ChangeConnectorStatus from './ChangeConnectorStatus'
import { faEdit, faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseConnectors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      connectors: [],
      isLoading: false,
      isCreateModalVisible: false,
      isUpdateModalVisible: false,
      isStatusModalVisible: false,
      connector_to_update: {},
      connector_record_to_update : {},
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Vendor ID",
        render: (i, record) => record.vendor.id
      },
      {
        title: "Vendor Name",
        render: (i, record) => record.vendor.name
      },
      {
        title: "CID",
        dataIndex: 'cid',
      },
      {
        title: "Host",
        dataIndex: 'host',
      },
      {
        title: "Port",
        dataIndex: 'port',
      },
      {
        title: "Username",
        dataIndex: 'username',
      },
      {
        title: "Password",
        dataIndex: 'password',
      },
      {
        title: "Status",
        align: "center",
        width: '150px',
        render: (i, record) => (
          <>
            {record.status === 'started' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
              : <Avatar style={{ backgroundColor: 'grey' }} size="small" />
            }
          </>
        )
      },

      {
        title: "Action",
        align: "center",
        width: '250px',
        render: (text, record) => (
          <span>
            <ButtonGroup>
              <Button title='Change Status' onClick={() => {this.showChangeStatusModal(true, record); }}>
                <FontAwesomeIcon icon={faPlayCircle} />
              </Button>
              <Button title='Update' onClick={() => {this.showUpdateModal(true, record); }}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button title='Delete Connector'>
                <Popconfirm placement="left" title='Are you sure to delete this connector?' onConfirm={() => {this.deleteConfirm(record.cid)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
            </ButtonGroup>
          </span>
        )
      }
    ]
  };

  deleteConfirm = (cid) => {
    const response = connectorsAPI().delete({cid:cid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.connectors];
      this.setState({ connectors: dataSource.filter(item => item.cid !== cid) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Connectors)
      this.context.selectMenu(MenuKeys.Connectors);
    this.getAllConnectors();

  }

  getAllConnectors = () => {
    const response = connectorsAPI().getAll()
    response.then(response => {
      if(response.data.connectors.length > 0){
        this.setState({connectors: response.data.connectors});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });   
  };

  showCreateModal = (is_visible, is_updated=false) => {
    this.setState({ isCreateModalVisible: is_visible });
    if(is_updated){
      this.getAllConnectors(); 
    }
  }
  
  showUpdateModal = (is_visible, record, is_updated=false) => {
    this.setState({ isUpdateModalVisible: is_visible, connector_record_to_update: record});
    if(is_updated){
      this.getAllConnectors(); 
    }
  }

  showChangeStatusModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isStatusModalVisible: is_visible, connector_to_update: record});
    if(is_updated){
      this.getAllConnectors(); 
    }
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Connectors"
        >
        {/* { this.state.isCreateModalVisible && <CreateVendorModal visible={this.state.isCreateModalVisible} onCancel={this.showCreateModal} /> } */}
        {
          this.state.connectors.length > 0 &&
          <>
            {
              Object.entries(this.state.connector_record_to_update).length !== 0 &&
              <UpdateConnector visible={this.state.isUpdateModalVisible} connector={this.state.connector_record_to_update} onCancel={this.showUpdateModal}  />
            }
            {
              Object.entries(this.state.connector_to_update).length !== 0 &&
              <ChangeConnectorStatus visible={this.state.isStatusModalVisible} connector={this.state.connector_to_update} onCancel={this.showChangeStatusModal}  />
            }
            <Table 
              rowKey={record => record.cid} 
              columns={this.GetColumn()} 
              dataSource={this.state.connectors} 
              scroll={{x:1200}}
              pagination={{ 
                position: 'both',
                size: 'large',
                showTotal: this.showTotal,
              }}
              bordered />
          </>
        }
      </Card>
    );
  }
}

BaseConnectors.contextType = AppContext;
export default BaseConnectors
