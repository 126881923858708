import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Row, Col, Form, Input, message, Select, Modal, Typography } from 'antd';
import { connectorsAPI } from '../../../utility/API';

const { Option } = Select;
class UpdateConnector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      connectors: [],
      user: {},
      visible: false,
      confirmLoading: false

    };
    this.formRef = React.createRef();
  }

  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = connectorsAPI().update(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false});
        this.props.onCancel(false, {}, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({confirmLoading: false});
        }
      });
    });
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  getGroupOptions = () => {
    let children = [];
    for (let index = 0; index < this.state.groups.length; index++) {
      let child_obj = this.state.groups[index];
      children.push(<Option key={child_obj.name}>{child_obj.name}</Option>);
    }
    return children;
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  onCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, onCancel } = this.props;
    let { connector } = this.props;
    return (
      <Modal confirmLoading={this.state.confirmLoading} width={1000} visible={visible} title="Edit connector" okText="Update" cancelText="Cancel" onCancel={() => {onCancel(false, {});}} onOk={this.onOk}>
        {Object.entries(connector).length !== 0 &&
          <Form
            initialValues={{
              cid: connector.cid,
              logfile: connector.logfile,
              logrotate: connector.logrotate,
              loglevel: connector.loglevel,
              host: connector.host,
              port: connector.port,
              ssl: connector.ssl,
              username: connector.username,
              password: connector.password,
              bind: connector.bind,
              bind_to: connector.bind_to,
              trx_to: connector.trx_to,
              res_to: connector.res_to,
              pdu_red_to: connector.pdu_red_to ,
              con_loss_retry: connector.con_loss_retry,
              con_loss_delay: connector.con_loss_delay,
              con_fail_retry: connector.con_fail_retry,
              con_fail_delay: connector.con_fail_delay,
              src_addr: connector.src_addr,
              src_ton: connector.src_ton,
              src_npi: connector.src_npi,
              dst_ton: connector.dst_ton,
              dst_npi: connector.dst_npi,
              bind_ton: connector.bind_ton,
              bind_npi: connector.bind_npi,
              validity: connector.validity,
              priority: connector.priority,
              requeue_delay: connector.requeue_delay,
              addr_range: connector.addr_range,
              systype: connector.systype,
              dlr_expiry: connector.dlr_expiry,
              submit_throughput: connector.submit_throughput,
              proto_id: connector.proto_id,
              coding: connector.coding,
              elink_interval: connector.elink_interval,
              def_msg_id: connector.def_msg_id,
              ripf: connector.ripf,
              dlr_msgid: connector.dlr_msgid
            }}
            ref={this.formRef}
            name="update_user"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Row gutter={24}>   
              <Col sm={12} md={12} lg={12}>
                
                <Form.Item label="CID Name">
                  <Form.Item name="cid" rules={VALIDATION_RULES['cid']} noStyle>
                    <Input className="Input" placeholder="CID Name" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Connector ID (must be unique)
                  </Typography.Text>
                </Form.Item>
                
                <Form.Item name="logfile" rules={VALIDATION_RULES['required']}>
                  <Input className="Input" placeholder="logfile" />
                </Form.Item>
                
                <Form.Item label="Log Rotate">
                  <Form.Item  name="logrotate" rules={VALIDATION_RULES['uid']} noStyle>
                    <Input className="Input" placeholder="logrotate" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    When to rotate the log file, possible values: S=Seconds, M=Minutes, H=Hours, D=Days, W0-W6=Weekday (0=Monday) and midnight=Roll over at midnight
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Log Level">
                  <Form.Item  name="loglevel" rules={VALIDATION_RULES['required']}>
                    <Input className="Input" placeholder="loglevel" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Logging numeric level: 10=DEBUG, 20=INFO, 30=WARNING, 40=ERROR, 50=CRITICCAL
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Host">
                  <Form.Item name="host" rules={VALIDATION_RULES['required']} noStyle>
                    <Input className="Input" placeholder="host" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Server that runs SMSC
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Port">
                  <Form.Item name="port" rules={VALIDATION_RULES['required']} noStyle>
                    <Input className="Input" placeholder="port" /> 
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    The port number for the connection to the SMSC.
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="SSL">
                  <Form.Item name="ssl" rules={VALIDATION_RULES['required']} noStyle>
                    <Input className="Input" placeholder="SSL" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Activate ssl connection
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Username">
                  <Form.Item name="username" rules={VALIDATION_RULES['username']} noStyle>
                    <Input className="Input" placeholder="Username" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    A unique username used for authentication
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Password" name="password" rules={VALIDATION_RULES['non-required-password']}>
                  <Input className="Input" placeholder="Password" />
                </Form.Item>

                <Form.Item label="Bind">
                  <Form.Item name="bind"  noStyle>
                    <Select style={{ width: '100%' }} placeholder="Select Group" >
                      <Option key="transceiver">transceiver</Option>
                      <Option key="receiver">receiver</Option>
                      <Option key="transmitter">transmitter</Option>
                    </Select>
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Bind type: transceiver, receiver or transmitter
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Bind To">
                  <Form.Item name="bind_to"  noStyle>
                    <Input className="Input" placeholder="Bind To" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Timeout for response to bind request
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Trx To">
                  <Form.Item name="trx_to" noStyle>
                    <Input className="Input" placeholder="Trx To" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Maximum time lapse allowed between transactions, after which, the connection is considered as inactive and will reconnect
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Res To">
                  <Form.Item name="res_to" noStyle>
                    <Input className="Input" placeholder="Res To" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Timeout for responses to any request PDU
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="PDU Red To">
                  <Form.Item name="pdu_red_to" noStyle>
                    <Input className="Input" placeholder="PDU Red To" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Timeout for reading a single PDU, this is the maximum lapse of time between receiving PDU’s header and its complete read, if the PDU reading timed out, the connection is considered as ‘corrupt’ and will reconnect
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Con. Loss Retry"  >
                  <Form.Item name="con_loss_retry" noStyle>
                    <Select style={{ width: '100%' }} placeholder="Con. Loss Retry" >
                      <Option key="yes">Yes</Option>
                      <Option key="no">No</Option>
                    </Select>
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Reconnect on connection loss ? (yes, no)
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Con. Loss Delay"  >
                  <Form.Item name="con_loss_delay" noStyle>
                    <Input className="Input" placeholder="Con. Loss Delay" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Reconnect delay on connection Loss (seconds)
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Con. Fail Retry"  >
                  <Form.Item name="con_fail_retry" noStyle>
                    <Select style={{ width: '100%' }} placeholder="Con. Fail Retry" >
                      <Option key="yes">Yes</Option>
                      <Option key="no">No</Option>
                    </Select>
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Reconnect on connection Fail ? (yes, no)
                  </Typography.Text>
                </Form.Item>
                
                <Form.Item label="Con. Fail Delay" >
                  <Form.Item name="con_fail_delay" noStyle>
                    <Input className="Input" placeholder="Con. Fail Delay" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Reconnect delay on connection Fail (seconds)
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Src. Addr."  >
                  <Form.Item name="src_addr" noStyle>
                    <Input className="Input" placeholder="Src. Addr." />
                  </Form.Item>  
                  <Typography.Text className="ant-form-text" type="secondary">
                    Default source adress of each SMS-MT if not set while sending it, can be numeric or alphanumeric, when not defined it will take SMSC default
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Src. TON"  >
                  <Form.Item name="src_ton" noStyle>
                    <Input className="Input" placeholder="Src. TON" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Source address TON setting for the link: 0=Unknown, 1=International, 2=National, 3=Network specific, 4=Subscriber number, 5=Alphanumeric, 6=Abbreviated
                  </Typography.Text>
                </Form.Item>

              </Col>
              <Col sm={12} md={12} lg={12}>
                
                <Form.Item label="Src. NPI">
                  <Form.Item name="src_npi" noStyle>
                    <Input className="Input" placeholder="Src. NPI" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Source address NPI setting for the link: 0=Unknown, 1=ISDN, 3=Data, 4=Telex, 6=Land mobile, 8=National, 9=Private, 10=Ermes, 14=Internet, 18=WAP Client ID
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Dst. TON"  >
                  <Form.Item name="dst_ton" noStyle>
                    <Input className="Input" placeholder="Dst. TON" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Destination address TON setting for the link: 0=Unknown, 1=International, 2=National, 3=Network specific, 4=Subscriber number, 5=Alphanumeric, 6=Abbreviated
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Dst. NPI"  >
                  <Form.Item name="dst_npi" noStyle>
                    <Input className="Input" placeholder="Dst. NPI" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Destination address NPI setting for the link: 0=Unknown, 1=ISDN, 3=Data, 4=Telex, 6=Land mobile, 8=National, 9=Private, 10=Ermes, 14=Internet, 18=WAP Client ID
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Bind TON"  >
                  <Form.Item name="bind_ton" noStyle>
                    <Input className="Input" placeholder="Bind TON" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Bind address TON setting for the link: 0=Unknown, 1=International, 2=National, 3=Network specific, 4=Subscriber number, 5=Alphanumeric, 6=Abbreviated
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Bind NPI">
                  <Form.Item name="bind_npi" noStyle>
                    <Input className="Input"placeholder="Bind NPI" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Bind address NPI setting for the link: 0=Unknown, 1=ISDN, 3=Data, 4=Telex, 6=Land mobile, 8=National, 9=Private, 10=Ermes, 14=Internet, 18=WAP Client ID
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Validity">
                  <Form.Item name="validity" noStyle>
                    <Input className="Input" placeholder="Validity" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Default validity period of each SMS-MT if not set while sending it, when not defined it will take SMSC default (seconds)
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Priority">
                  <Form.Item name="priority" noStyle>
                    <Input className="Input" placeholder="Priority" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    SMS-MT default priority if not set while sending it: 0, 1, 2 or 3
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Requeue Delay">
                  <Form.Item name="requeue_delay" noStyle>
                    <Input className="Input" placeholder="Requeue Delay" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Delay to be considered when requeuing a rejected message
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Addr. Range">
                  <Form.Item name="addr_range" noStyle>
                    <Input className="Input" placeholder="Addr. Range" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Indicates which MS’s can send messages to this connector, seems to be an informative value
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="SysType">
                  <Form.Item name="systype" noStyle>
                    <Input className="Input" placeholder="SysType" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    The system_type parameter is used to categorize the type of ESME that is binding to the SMSC. Examples include “VMS” (voice mail system) and “OTA” (over-the-air activation system).
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Dlr. Expiry" >
                  <Form.Item  name="dlr_expiry" noStyle>
                    <Input className="Input" placeholder="Dlr. Expiry" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    When a SMS-MT is not acked, it will remain waiting in memory for dlr_expiry seconds, after this period, any received ACK will be ignored
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Submit Throughput" >
                  <Form.Item  name="submit_throughput" noStyle>
                    <Input className="Input" placeholder="Submit Throughput" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Active SMS-MT throttling in MPS (Messages per second), set to 0 (zero) for unlimited throughput
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Proto ID">
                  <Form.Item name="proto_id" noStyle>
                    <Input className="Input" placeholder="Proto ID" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Used to indicate protocol id in SMS-MT and SMS-MO
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Coding" >
                  <Form.Item  name="coding" noStyle>
                    <Input className="Input" placeholder="Coding" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Default coding of each SMS-MT if not set while sending it: 0=SMSC Default, 1=IA5 ASCII, 2=Octet unspecified, 3=Latin1, 4=Octet unspecified common, 5=JIS, 6=Cyrillic, 7=ISO-8859-8, 8=UCS2, 9=Pictogram, 10=ISO-2022-JP, 13=Extended Kanji Jis, 14=KS C 5601
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Enquire Link Interval">
                  <Form.Item  name="elink_interval" noStyle>
                    <Input className="Input" placeholder="Enquire Link Interval" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Enquire link interval (seconds)
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Def. Msg ID">
                  <Form.Item  name="def_msg_id" noStyle>
                    <Input className="Input" placeholder="Def. Msg ID" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Specifies the SMSC index of a pre-defined (‘canned’) message.
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="RIPF"> 
                  <Form.Item name="ripf" noStyle>
                    <Input className="Input" placeholder="RIPF" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Replace if present flag: 0=Do not replace, 1=Replace
                  </Typography.Text>
                </Form.Item>

                <Form.Item label="Dlr. MSJ ID">
                  <Form.Item  name="dlr_msgid" noStyle>
                    <Input className="Input" placeholder="Dlr. MSJ ID" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Indicates how to read msg id when receiving a receipt: 0=msg id is identical in submit_sm_resp and deliver_sm, 1=submit_sm_resp msg-id is in hexadecimal base, deliver_sm msg-id is in decimal base, 2=submit_sm_resp msg-id is in decimal base, deliver_sm msg-id is in hexadecimal base.
                  </Typography.Text>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        }
      </Modal>
    )
  }
}

export default UpdateConnector
