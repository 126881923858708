import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { groupsAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import CreateGroupModal from "./CreateGroup";
import ChangeGroupStatus from './ChangeGroupStatus'
import { faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      groups: [],
      isLoading: false,
      isCreateModalVisible: false,
      isStatusModalVisible: false,
      group_to_update: {}
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Group ID",
        dataIndex: 'name',
      },
      {
        title: "Status",
        align: "center",
        render: (i, record) => (
          <>
            {record.status === 'enabled' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
              : <Avatar style={{ backgroundColor: 'grey' }} size="small"  />
            }
          </>
        )
      },

      {
        title: "Action",
        align: "center",
        render: (text, record) => (
          <ButtonGroup>
            <Button title='Update Status' onClick={() => {this.showChangeStatusModal(true, record); }}>
              <FontAwesomeIcon icon={faPlayCircle} />
            </Button>
            <Button title='Delete Group'>
                <Popconfirm placement="left" title='Are you sure to delete this group?' onConfirm={() => {this.deleteConfirm(record.name)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
          </ButtonGroup>
        )
      }
    ]
  };

  deleteConfirm = (gid) => {
    const response = groupsAPI().delete({gid:gid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.groups];
      this.setState({ groups: dataSource.filter(item => item.name !== gid) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }
  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Groups)
      this.context.selectMenu(MenuKeys.Groups);
    this.getAllGroups();
   
  }

  getAllGroups = () => {
    const response = groupsAPI().getAll()
    response.then(response => {
      if(response.data.groups.length > 0){
        this.setState({groups: response.data.groups, mounted: true});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showCreateModal = (is_visible, is_updated=false) => {
    this.setState({ isCreateModalVisible: is_visible });
    if(is_updated){
      this.getAllGroups(); 
    }
  }

  showChangeStatusModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isStatusModalVisible: is_visible, group_to_update: record});
    if(is_updated){
      this.getAllGroups(); 
    }
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Groups"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => { this.showCreateModal(true); }} title="Create New Group">
            Add New
          </Button>
        }>
        <CreateGroupModal visible={this.state.isCreateModalVisible} onCancel={this.showCreateModal} />
        {
          this.state.groups.length > 0 &&
          <>
            <ChangeGroupStatus visible={this.state.isStatusModalVisible} group={this.state.group_to_update} onCancel={this.showChangeStatusModal}  />
            <Table 
              rowKey={record => record.name} 
              columns={this.GetColumn()} 
              dataSource={this.state.groups} 
              pagination={{ 
                position: 'both',
                size: 'large',
                showTotal: this.showTotal,
              }} 
              bordered />
          </>
        }
      </Card>
    );
  }
}

BaseGroups.contextType = AppContext;
export default BaseGroups
