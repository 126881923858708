import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { Card } from "antd";
import { MenuKeys } from "../../utility/constants";
import { customStyle } from '../../customStyle';
import AppContext from "../AppContext";

class Profile extends Component{
    componentDidMount(){
        if(this.context.menuKey !== MenuKeys.Profile)
        this.context.selectMenu(MenuKeys.Profile)
    }
    render(){
        return (
            <Card className="custom-card" bordered={false} title="Profile" headStyle={customStyle.headStyle}>
                
            </Card>
        );
    }
}

Profile.contextType = AppContext;
export default Profile;