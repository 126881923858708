import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal } from 'antd';
import { vendorsAPI, usersAPI } from '../../../utility/API'

const { TextArea } = Input;
const { Option } = Select;

const getVendorsOptions = (rate_cards) => {
  let children = [];
  for (let index = 0; index < rate_cards.length; index++) {
    let child_obj = rate_cards[index];
    children.push(<Option key={child_obj.uuid}>{child_obj.nick_name} >> {child_obj.country_code} >> {child_obj.rate}</Option>);
  }
  return children;
};


class SendTestSMS extends Component {

  formRef = React.createRef();

  constructor(props) {
    super(props)
    this.state = {
      rate_cards: [],
      confirmLoading: false,
    }
  }
  

  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,             
    }, ()=>{
      const response = usersAPI().sendTestSMS(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({ confirmLoading: false });
        this.props.onCancel(false, true);

      }).catch(error => {
        if (error.response) {
          this.setState({ confirmLoading: false });
          message.error('Request Failed');
        }
      });
    });
  };

  componentDidMount() {
  
    vendorsAPI().getRateCards().then(response => {
      if (response.data.rate_cards.length > 0) {
        this.setState({ rate_cards: response.data.rate_cards });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  }

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Send Test SMS" okText="Send" cancelText="Cancel" onCancel={() => { onCancel(false, {}); }} onOk={() => { this.formRef.current.submit(); }}>
        <Form
          ref={this.formRef}
          name="send_test_sms"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item label="Routes" name="rate_card_id" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select Vendor Route">
              {getVendorsOptions(this.state.rate_cards)}
            </Select>
          </Form.Item>
          <Form.Item label="To Number" name="to_number" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Type here" />
          </Form.Item>
          <Form.Item label="Message" name="content" rules={VALIDATION_RULES['required']}>
            <TextArea className="Input" rows={4} placeholder="Type here" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default SendTestSMS;