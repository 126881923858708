import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Row, Col, Form, Input, message, Select, Modal, Typography } from 'antd';
import { adminUsersAPI, groupsAPI } from '../../../utility/API'

const { Option } = Select;
class UpdateUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      connectors: [],
      user: {},
    };
    this.formRef = React.createRef();
  }

  onHandleFinish = values => {
    const response = adminUsersAPI().update(values)
    response.then(response => {
      message.success(response.data.message);
      this.props.onCancel(false, {}, true);
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  componentDidMount() {
    const response = groupsAPI().getAll()
    response.then(response => {
      if (response.data.groups.length > 0) {
        this.setState({ groups: response.data.groups, mounted: true });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        // message.error('Request Failed');
      }
    });
  }

  getGroupOptions = () => {
    let children = [];
    for (let index = 0; index < this.state.groups.length; index++) {
      let child_obj = this.state.groups[index];
      children.push(<Option key={child_obj.name}>{child_obj.name}</Option>);
    }
    return children;
  };

  getVendorsOptions = () => {
    let children = [];
    for (let index = 0; index < this.state.connectors.length; index++) {
      let child_obj = this.state.connectors[index];
      children.push(<Option key={child_obj.cid}>{child_obj.cid}</Option>);
    }
    return children;
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  render() {
    const { visible, onCancel } = this.props;
    let { user } = this.props;
    return (
      <Modal width={1000} visible={visible} title="Edit user" okText="Update" cancelText="Cancel" onCancel={() => {onCancel(false, {});}} onOk={this.onOk}>
        {Object.entries(user).length !== 0 ?
          <Form
            initialValues={{
              username:user.username,
              uid:user.uid,
              gid:user.gid,
              balance:user.mt_messaging_cred.quota.balance,
              sms_count:user.mt_messaging_cred.quota.sms_count,
              early_percent:user.mt_messaging_cred.quota.early_percent,
              smpps_throughput:user.mt_messaging_cred.quota.smpps_throughput,
              http_throughput:user.mt_messaging_cred.quota.http_throughput,
              http_send:user.mt_messaging_cred.authorization.http_send,
              http_balance:user.mt_messaging_cred.authorization.http_balance,
              http_rate:user.mt_messaging_cred.authorization.http_rate,
              http_bulk:user.mt_messaging_cred.authorization.http_bulk,
              smpps_send:user.mt_messaging_cred.authorization.smpps_send,
              http_long_content:user.mt_messaging_cred.authorization.http_long_content,
              dlr_level:user.mt_messaging_cred.authorization.dlr_level,
              src_addr:user.mt_messaging_cred.authorization.src_addr,
              priority:user.mt_messaging_cred.authorization.priority,
              validity_period:user.mt_messaging_cred.authorization.validity_period,
              src_addr_f:user.mt_messaging_cred.valuefilter.src_addr,
              dst_addr_f:user.mt_messaging_cred.valuefilter.dst_addr,
              content_f:user.mt_messaging_cred.valuefilter.content,
              validity_period_f:user.mt_messaging_cred.valuefilter.validity_period,
              src_addr_d:user.mt_messaging_cred.defaultvalue.src_addr,
            }}
            ref={this.formRef}
            name="update_user"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col sm={12} md={12} lg={12}>
                <Form.Item label="Username">
                  <Form.Item name="username" rules={VALIDATION_RULES['username']} noStyle>
                    <Input className="Input" placeholder="Username" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    A unique username used for authentication
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Password" name="password" rules={VALIDATION_RULES['non-required-password']}>
                  <Input className="Input" placeholder="Password" />
                </Form.Item>
                <Form.Item label="UID"> 
                  <Form.Item name="uid" rules={VALIDATION_RULES['uid']} noStyle>
                    <Input className="Input" placeholder="UID" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    A unique identifier, can be same as username
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Group">
                  <Form.Item name="gid" rules={VALIDATION_RULES['gid']} noStyle>
                    <Select style={{ width: '100%' }} placeholder="Select Group" >
                      {this.getGroupOptions()}
                    </Select>
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Group Identfier
                  </Typography.Text>
                </Form.Item>
                
              </Col>
              <Col sm={12} md={12} lg={12}>
                <h2 orientation="left">Quota</h2>
                {/* <Divider /> */}
                <Form.Item label="Balance quota" name="balance" >
                  <Input className="Input" placeholder="Balance" />
                </Form.Item>
                <Form.Item label="SMS Count" name="sms_count" >
                  <Input className="Input" placeholder="SMS Count" />
                </Form.Item>
                <Form.Item label="Early Percent" name="early_percent">
                  <Input className="Input" placeholder="Early Percent" />
                </Form.Item>
                <Form.Item label="SMPPS Throughput">
                  <Form.Item name="smpps_throughput" noStyle>
                    <Input className="Input" placeholder="SMPPS Throughput" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Max. number of messages per second to accept through SMPP Server
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="HTTP Throughput">
                  <Form.Item name="http_throughput" noStyle>
                    <Input className="Input" placeholder="HTTP Throughput" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Max. number of messages per second to accept through HTTP API
                  </Typography.Text>
                </Form.Item>
              </Col>
            </Row>
              
              <Row gutter={24}>
              <Col sm={12} md={12} lg={12}>
                <h2 orientation="left">Authorization</h2>
                {/* <Divider /> */}
                <Form.Item label="http_send">
                  <Form.Item name="http_send" noStyle>
                    <Input className="Input" placeholder="http_send" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to send SMS through Sending SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="http_balance" >
                  <Form.Item name="http_balance" noStyle>
                    <Input className="Input" placeholder="http_balance" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to check balance through Checking account balance
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="http_rate" name="http_rate">
                  <Input className="Input" placeholder="http_rate" />
                </Form.Item>
                <Form.Item label="http_bulk" name="http_bulk" >
                  <Input className="Input" placeholder="http_bulk" />
                </Form.Item>
                <Form.Item label="smpps_send" name="smpps_send" >
                  <Input className="Input" placeholder="smpps_send" />
                </Form.Item>
                <Form.Item label="http_long_content" name="http_long_content" >
                  <Input className="Input" placeholder="http_long_content" />
                </Form.Item>
                <Form.Item label="dlr_level">
                  <Form.Item name="dlr_level" noStyle>
                    <Input className="Input" placeholder="dlr_level" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to set dlr-level parameter (default is 1)
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="http_dlr_method">
                  <Form.Item name="http_dlr_method" noStyle>
                    <Input className="Input" placeholder="http_dlr_method" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to set dlr-method HTTP parameter (default is GET)
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="src_addr">
                  <Form.Item name="src_addr" noStyle>
                    <Input className="Input" placeholder="src_addr" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to defined source address of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="priority">
                  <Form.Item name="priority" noStyle>
                    <Input className="Input" placeholder="priority" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to defined priority of SMS-MT (default is 0)
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="validity_period">
                  <Form.Item name="validity_period" noStyle>
                    <Input className="Input" placeholder="validity_period" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Privilege to defined validity_period of SMS-MT (default is NOT SET)
                  </Typography.Text>
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <h2 orientation="left">Value Filter</h2>
                {/* <Divider /> */}
                <Form.Item label="Src. Address">
                  <Form.Item name="src_addr_f" noStyle>
                    <Input className="Input" placeholder="Src. Addr." />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Regex pattern to validate source address of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Dst. Address">
                  <Form.Item name="dst_addr_f" noStyle>
                    <Input className="Input" placeholder="Dst. Addr." />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Regex pattern to validate destination address of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Content">
                  <Form.Item name="content_f" noStyle>
                    <Input className="Input" placeholder="Content" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Regex pattern to validate content of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Priority">
                  <Form.Item name="priority_f" noStyle>
                    <Input className="Input" placeholder="Priority" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Regex pattern to validate priority of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <Form.Item label="Validity Period">
                  <Form.Item name="validity_period_f" >
                    <Input className="Input" placeholder="Validity Period" />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Regex pattern to validate validity_period of SMS-MT
                  </Typography.Text>
                </Form.Item>
                <h2 orientation="left">Default Value</h2>
                {/* <Divider /> */}
                <Form.Item label="Src. Address">
                  <Form.Item name="src_addr_d" noStyle>
                    <Input className="Input" placeholder="Src. Addr." />
                  </Form.Item>
                  <Typography.Text className="ant-form-text" type="secondary">
                    Default source address of SMS-MT
                  </Typography.Text>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          : null
        }
      </Modal>
    )
  }
}

export default UpdateUser
