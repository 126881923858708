
import React from 'react'
import { Row, Col, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Spinner() {
    return (
        <Row type="flex" align="middle">
            <Col span={4}>
                <Spin tip="Loading..." style={{position:"fixed",left:'50%',top:'50%'}} indicator={<FontAwesomeIcon icon="spinner" style={{ fontSize: '50px', marginBottom: 10 }} spin />} />
            </Col>
        </Row>            
    )
}

export default Spinner
