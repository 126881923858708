import React, { Component } from 'react';
import { VALIDATION_RULES, MTROUTE_TYPES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal } from 'antd';
import { connectorsAPI, filtersAPI, mtroutesAPI } from '../../../utility/API'

const { Option } = Select;

class CreateMTRouterModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mtrouters: {},
      connectors: [],
      filters: [],   
      confirmLoading: false    
    };
    this.formRef = React.createRef();
  }
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = mtroutesAPI().create(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false});
      });
    });

  };
  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };
  
  componentDidMount() {
    
    connectorsAPI().getAll().then(response => {
      if(response.data.connectors.length > 0){
        this.setState({connectors: response.data.connectors});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
    
    filtersAPI().getAll().then(response => {
      if(response.data.filters.length > 0){
        this.setState({filters: response.data.filters});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  }

  onOk = () =>{
    this.formRef.current.submit();
  }

  getVendorsOptions = () => {
    let children = [];
    for (let index = 0; index < this.state.connectors.length; index++) {
      let child_obj = this.state.connectors[index];
      children.push(<Option key={child_obj.cid}>{child_obj.cid}</Option>);
    }
    return children;
  };

  getFiltersOptions = () => {
    let children = [];
    for (let index = 0; index < this.state.filters.length; index++) {
      let child_obj = this.state.filters[index];
      children.push(<Option key={child_obj.fid}>{child_obj.fid}</Option>);
    }
    return children;
  };

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Create a new MTRouter" okText="Create" cancelText="Cancel" onCancel={() => { onCancel(false); }}  onOk={this.onOk}>
        <Form
          ref={this.formRef}
          name="login"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical" 
        >
          <Form.Item label="Route Type" name="type" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select Type" >
              {MTROUTE_TYPES.map(function(name, index){
                return <Option key={ name }>{name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Order" name="order" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="1, 2, ..." />
          </Form.Item>
          <Form.Item label="Rate" name="rate" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Rate" />
          </Form.Item>
          <Form.Item label="Vendors" name="smppconnectors" rules={VALIDATION_RULES['required']}>
            <Select style={{ width: '100%' }} placeholder="Select Vendors" >
              {this.getVendorsOptions()}
            </Select>
          </Form.Item>
          <Form.Item label="Filters" name="filters">
            <Select style={{ width: '100%' }} mode="multiple" placeholder="Select Filters" >
              {this.getFiltersOptions()}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default CreateMTRouterModal;
