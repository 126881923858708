import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal} from 'antd';
import { adminUsersAPI } from '../../../utility/API'

const { Option } = Select;
class ChangeUserStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      connectors: [],
      user: {},
    };
    this.formRef = React.createRef();
  }

  onHandleFinish = values => {
    const response = adminUsersAPI().changeStatus(values)
    response.then(response => {
      message.success(response.data.message);
      this.props.onCancel(false, {}, true);
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });

  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  render() {
    const { visible, onCancel } = this.props;
    let { user } = this.props;
    return (
      <Modal visible={visible} title="Change User Status" okText="Update" cancelText="Cancel" onCancel={() => {onCancel(false, {});}} onOk={this.onOk}>
        {Object.entries(user).length !== 0 ?
          <Form
            initialValues={{ s: user.status, uid: user.uid }}
            ref={this.formRef}
            name="update_user_status"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item hidden={true} name="uid" >
              <Input className="Input" />
            </Form.Item>
            <Form.Item label="Status" name="s" rules={VALIDATION_RULES['required']}>
              <Select style={{ width: '100%' }} placeholder="Change status" >
                <Option key="enable">Enable</Option>
                <Option key="disable">Disable</Option>
              </Select>
            </Form.Item>
          </Form>
          : null
        }
      </Modal>
    )
  }
}

export default ChangeUserStatus