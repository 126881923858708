import React from 'react'
import { RequireAuth } from '../auth'
import { BaseDashboard } from './dashboard'
import { BaseBilling } from './billing'
import { BaseReports, BaseSurcharge } from './reports'
import { BaseUserRoutes } from './userroutes'
import { BaseDID } from './DID'

const UserRouter = () => {
  return (
    <React.Fragment>
      <RequireAuth exact path="/account/dashboard" component={BaseDashboard} />
      <RequireAuth exact path="/account/billing" component={BaseBilling} />
      <RequireAuth exact path="/account/reports" component={BaseReports} />
      <RequireAuth exact path="/account/surcharge" component={BaseSurcharge} />
      <RequireAuth exact path="/account/routes" component={BaseUserRoutes} />
      <RequireAuth exact path="/account/did" component={BaseDID} />
    </React.Fragment>
  )
}

export default UserRouter