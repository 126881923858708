import React, { Component } from 'react';
import moment from 'moment';
import "antd/dist/antd.css";
import { Card, message, Table, Form, Button, DatePicker, Select, Row, Col, Progress } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, VALIDATION_RULES, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { statsAPI, adminUsersAPI } from '../../../utility/API'

const { Option } = Select;
const { RangePicker } = DatePicker;

const getUserOptions = (users) => {
  let children = [<Option key="All">ALL</Option>];
  for (let index = 0; index < users.length; index++) {
    children.push(<Option key={users[index].uid}>{users[index].uid}</Option>);
  }
  return children;
};

const columnSource = [
  {
    title: "smpp-msgid",
    dataIndex: "smpp_msgid"
  },
  {
    title: "Created",
    dataIndex: "created_at"
  },
  {
    title: "uid",
    dataIndex: "uid"
  },
  {
    title: "To Number",
    dataIndex: "destination_addr"
  },
  {
    title: "From Number",
    dataIndex: "source_addr"
  },
  {
    title: "Message",
    dataIndex: "short_message"
  },
  {
    title: "Trials",
    dataIndex: "trials"
  },
  {
    title: "source_connector",
    dataIndex: "source_connector"
  },
  {
    title: "routed_cid",
    dataIndex: "routed_cid"
  },
  {
    title: "Status",
    dataIndex: "status"
  },
  {
    title: "Status At",
    dataIndex: "status_at"
  },

]

class UserReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sms_log: [],
      users: [],
      uid: 'All',
      start_date: moment().subtract(1, 'day'),
      end_date: moment(),
      confirmLoading: false,
      loading: false,
      progress: 0,
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.UserReport)
      this.context.selectMenu(MenuKeys.UserReport, MenuKeys.Sub1);
    if(this.state.users.length <= 0){
      this.getAllUser();
      this.getSMSLogs()
    }  
  };

  getAllUser = () => {
    const response = adminUsersAPI().getAll()
    response.then(response => {
      if(response.data.users.length > 0){
        this.setState({users: response.data.users, mounted: true});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  getSMSLogs = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'uid': this.state.uid,
        'start_date': this.state.start_date.format('YYYY-MM-DD'),
        'end_date': this.state.end_date.format('YYYY-MM-DD') 
      }
      const response = statsAPI().getSMSLogByUser(values, this.state.pagination.page, this.state.pagination.pageSize)
      response.then(response => {
        // console.log(response.data)
        if (response.data.sms_log.length > 0) {
          this.setState({ 
            sms_log: response.data.sms_log,
            pagination: { ...this.state.pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false
          });
        } else {
          message.error('No records found!');
          this.setState({ sms_log: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ sms_log: [], confirmLoading: false });
        }
      });
    });
  };

  onHandleFinish = fieldsValue => {
    // const rangeValue = fieldsValue['dateRange'];
    // this.setState({
    //   pagination: { ...this.state.pagination, page: 1 },
    //   uid: fieldsValue['uid'], start_date: rangeValue[0], end_date: rangeValue[1]
    // }, ()=>{
    //   this.getSMSLogs()      
    // });
  };

  onSubmitClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        pagination: { ...this.state.pagination, page: 1 },
        uid: values['uid'], start_date: rangeValue[0], end_date: rangeValue[1]
      }, ()=>{
        this.getSMSLogs()
      });
    }).catch(errorInfo => {
        
    });
  };
  
  onCSVDownloadClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        uid: values['uid'], start_date: rangeValue[0], end_date: rangeValue[1], loading:true
      }, ()=>{
        this.onCSVDownload()
      });
    }).catch(errorInfo => {
        
    });
  };

  onCSVDownloadProgress = (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      this.setState({ progress: percentCompleted });
  }

  onCSVDownload = () => {
    const values = {
      'uid': this.state.uid,
      'start_date': this.state.start_date.format('YYYY-MM-DD'),
      'end_date': this.state.end_date.format('YYYY-MM-DD'),
    }
    const response = statsAPI().getSMSLogCSV(values, this.onCSVDownloadProgress)
    response.then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.state.uid}_${this.state.start_date.format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    }).catch(error => {
      if (error.response) {
        this.setState({ loading: false });
        message.error('No records found!');
        // console.log(error.response)
      }
    });   
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getSMSLogs()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Outbound User Report">
        <Card className="mb-2 card-body" bordered={false} >
          <Form
            ref={this.formRef}
            name="user_report"
            initialValues={{ uid: this.state.uid, dateRange: [this.state.start_date, this.state.end_date] }}
            onFinish={this.onHandleFinish}
          >
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="Users" name="uid" rules={VALIDATION_RULES['required']}>
                  <Select style={{ width: '100%' }} placeholder="Select User" >
                    {getUserOptions(this.state.users)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Date Range" name="dateRange" rules={VALIDATION_RULES['required']}>
                  <RangePicker style={{ width: '100%' }}  ranges={{
                      'Yesterday': [moment().subtract(1, 'day'), moment()],
                      '30 Days': [moment().subtract(1, 'month').add(1, 'day'), moment()],
                      '60 Days': [moment().subtract(2, 'month').add(1, 'day'), moment()],
                      '90 Days': [moment().subtract(3, 'month').add(1, 'day'), moment()],
                    }}
                   size='middle' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button loading={this.state.confirmLoading} 
                  className="ml-2" type="primary" 
                  htmlType="submit"
                  onClick={this.onSubmitClick}
                  >
                    Search
                  </Button>
                  <Button loading={this.state.loading}  
                  className="ml-2" type="primary" htmlType="submit" 
                  onClick={this.onCSVDownloadClick}>
                    Download CSV 
                  </Button>
                  { this.state.loading && <Progress className="ml-2" percent={this.state.progress} steps={5} size="small" strokeColor="#52c41a" />}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {
          this.state.sms_log.length > 0 &&
          <Table 
          className="mb-5"
          rowKey={record => record.msgid} 
          columns={columnSource} 
          dataSource={this.state.sms_log} 
          pagination={{ 
            position: 'both',
            size: 'large',
            showQuickJumper: true,
            showSizeChanger: true,
            onChange:this.onShowSizeChange,
            onShowSizeChange: this.onShowSizeChange,
            total: this.state.pagination.total,
            showTotal: this.showTotal,
            defaultPageSize: this.state.pagination.pageSize,
            itemRender:this.itemRender,
          }}
          size="middle" 
          bordered />
        }
      </Card>
    );
  }
}

UserReports.contextType = AppContext;
export default UserReports
