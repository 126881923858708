import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal } from 'antd';
import { metadataAPI, vendorsAPI } from '../../../utility/API';

const { Option } = Select;
const getCountryCodeOptions = (country_codes) => {
  let children = [];
  for (let index = 0; index < country_codes.length; index++) {
    const element = country_codes[index];
    children.push(<Option key={element.code}>{element.name}</Option>);
  }
  return children;
};


class CreateRateCardModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      country_codes: [],
      confirmLoading: false
    };
    this.formRef = React.createRef();
  }
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, () => {
      const response = vendorsAPI().addRateCard(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({confirmLoading: false});
      });
    });
  };
  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }
  
  componentDidMount() {
    const response = metadataAPI().getCountryCodes()
    response.then(response => {
      if (response.data.country_codes.length > 0) {
        this.setState({ country_codes: response.data.country_codes });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
      }
    });

  }

  render() {
    const { visible, onCancel, vendor } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Add New Rate Card" okText="Create" cancelText="Cancel" onCancel={() => { onCancel(false, {}); }} onOk={this.onOk}>
        <Form
          ref={this.formRef}
          initialValues={{
            vid: vendor.vid
          }}
          name="login"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item hidden={true} name="vid" >
            <Input className="Input" />
          </Form.Item>
          <Form.Item label="Route Country Code" name="country_code" rules={VALIDATION_RULES['required']}>
            <Select showSearch style={{ width: '100%' }} placeholder="Select Country Code" >
              {this.state.country_codes && getCountryCodeOptions(this.state.country_codes)}
            </Select>
          </Form.Item>
          <Form.Item label="Message Rate" name="route_rate" rules={VALIDATION_RULES['required']}>
            <Input className="Input" placeholder="Rate" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default CreateRateCardModal;
