import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { adminUsersAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import CreateUserModal from "./CreateUser/FinalForm";
import UpdateUser from './UpdateUser'
import ChangeUserStatus from './ChangeUserStatus';
import UpdateUserBalance from './UpdateUserBalance';
import RouteRateChangeModal from './RouteRateChange';

import { faEdit, faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      mounted: false,
      isCreateModalVisible: false,
      isUpdateModalVisible: false,
      isStatusModalVisible: false,
      isBalanceModalVisible: false,
      isRouteRateChangeModalVisible: false,
      user_to_update: {},
      user_status_update : {},
      user_balance_update : {},
      user_route_rate_update : {},
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   key: 'index',
      //   width: '100px',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "UID",
        float: "right",
        dataIndex: 'uid',
      },
      {
        title: "GID",
        dataIndex: 'gid',
      },
      {
        title: "Username",
        dataIndex: "username",
      },
      {
        title: "Status",
        align: "center",
        width: '150px',
        render: (i, record) => (
          <>
            {record.status === 'enabled' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
              : <Avatar style={{ backgroundColor: 'grey' }} size="small" />
            }
          </>
        )
      },
      {
        title: "MT Messaging Credentials",
        dataIndex: "mt_messaging_cred",
        children: [
          {
            title: 'Balance',
            dataIndex: 'mt_messaging_cred.quota.balance',
            width: '150px',
            render: (text, record) => 
            <>
              {record.mt_messaging_cred.quota.balance}
              <FontAwesomeIcon className="ml-1" onClick={() => {this.showBalanceModal(true, record); }} icon={faEdit} />
            </>
          },
          {
            title: 'Route Rate',
            dataIndex: 'routes.rate',
            width: '150px',
            render: (text, record) => 
            <>
              {record.routes.rate}
              <FontAwesomeIcon className="ml-1" onClick={() => {this.showRouteRateChangeModal(true, record); }} icon={faEdit} />
            </>
          },
        ]
      },
      {
        title: "Action",
        align: "center",
        width: '250px',
        render: (text, record) => (
          <span>
            <ButtonGroup>
              <Button onClick={() => {this.showChangeStatusModal(true, record); }}>
                <FontAwesomeIcon icon={faPlayCircle} />
              </Button>
              <Button onClick={() => {this.showUpdateModal(true, record); }}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button>
                <Popconfirm placement="left" title='Are you sure to delete this user?' onConfirm={() => {this.deleteConfirm(record.uid)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
            </ButtonGroup>
          </span>
        )
      }
    ]
  };

  deleteConfirm = (uid) => {
    const response = adminUsersAPI().delete({uid:uid})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.users];
      this.setState({ users: dataSource.filter(item => item.uid !== uid) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }

  componentDidMount() {
    if(this.state.users.length <= 0){
      this.getAllUser();
    }  
  }
  getAllUser = () => {
    const response = adminUsersAPI().getAll()
    response.then(response => {
      if(response.data.users.length > 0){
        this.setState({users: response.data.users, mounted: true});
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showCreateModal = (is_visible, is_updated=false) => {
    this.setState({ isCreateModalVisible: is_visible });
    if(is_updated){
      this.getAllUser(); 
    }
  }
  
  showUpdateModal = (is_visible, record, is_updated=false) => {
    this.setState({ isUpdateModalVisible: is_visible, user_to_update: record});
    if(is_updated){
      this.getAllUser(); 
    }
  }

  showChangeStatusModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isStatusModalVisible: is_visible, user_status_update: record});
    if(is_updated){
      this.getAllUser(); 
    }
  }

  showBalanceModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isBalanceModalVisible: is_visible, user_balance_update: record});
    if(is_updated){
      this.getAllUser(); 
    }
  }
  
  showRouteRateChangeModal  = (is_visible, record, is_updated=false) => {
    if(is_updated){
      this.getAllUser(); 
    }
    this.setState({ isRouteRateChangeModalVisible: is_visible, user_route_rate_update: record});
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Users"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => {this.showCreateModal(true); }} title="Create New User">
            Add New
          </Button>
        }>
          { this.state.isCreateModalVisible && <CreateUserModal visible={this.state.isCreateModalVisible} onCancel={this.showCreateModal}  />}
        
        {
          this.state.users.length > 0 &&
          <>
            { this.state.isUpdateModalVisible && <UpdateUser visible={this.state.isUpdateModalVisible} user={this.state.user_to_update} onCancel={this.showUpdateModal} /> }
            { Object.entries(this.state.user_status_update).length !== 0 && <ChangeUserStatus visible={this.state.isStatusModalVisible} user={this.state.user_status_update} onCancel={this.showChangeStatusModal}  /> }
            { Object.entries(this.state.user_balance_update).length !== 0 && <UpdateUserBalance visible={this.state.isBalanceModalVisible} user={this.state.user_balance_update} onCancel={this.showBalanceModal}  /> }
            { this.state.isRouteRateChangeModalVisible && Object.entries(this.state.user_route_rate_update).length !== 0 && <RouteRateChangeModal visible={this.state.isRouteRateChangeModalVisible} user={this.state.user_route_rate_update} onCancel={this.showRouteRateChangeModal}  /> }
            <Table 
              rowKey={record => record.uid} 
              columns={this.GetColumn()} 
              dataSource={this.state.users} 
              pagination={{ 
                position: 'both',
                size: 'large',
                showTotal: this.showTotal,
              }} 
              bordered />     
          </>
        }
      </Card>
    );
  }
}

BaseUsers.contextType = AppContext;
export default BaseUsers
