import React, { Component } from 'react';
import moment from 'moment';
import "antd/dist/antd.css";
import { Card, message, Table, Form, Button, DatePicker,  Row, Col } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, VALIDATION_RULES, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { statsAPI } from '../../../utility/API'

const { RangePicker } = DatePicker;

const statsColumnSource = [
   {
    title: "Date",
    dataIndex: "day"
  },
  {
    title: "SMS Count",
    dataIndex: "sms_count"
  },
  {
    title: "Surcharge Amount",
    dataIndex: "sum_amount"
  }
]

class BaseSurcharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surcharge_stats: [],
      start_date: moment().subtract(7, 'day'),
      end_date: moment(),
      confirmLoading: false,
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.UserSurchargeReport)
      this.context.selectMenu(MenuKeys.UserSurchargeReport, MenuKeys.UserSub1);
    if(this.state.surcharge_stats.length <= 0){
      this.getSurchargeStats()
    }  
  };

  getSurchargeStats = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'uid': this.context.user.uid,
        'start_date': this.state.start_date.format('YYYY-MM-DD'),
        'end_date': this.state.end_date.format('YYYY-MM-DD') 
      }
      const response = statsAPI().getSurchargeStats(values, this.state.pagination.page, this.state.pagination.pageSize)
      response.then(response => {
        if (response.data.surcharge_stats.length > 0) {
          this.setState({ 
            surcharge_stats: response.data.surcharge_stats,
            pagination: { ...this.state.pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false
          });
        } else {
          message.error('No records found!');
          this.setState({ surcharge_stats: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ surcharge_stats: [], confirmLoading: false });
        }
      });
    });
  };

  onHandleFinish = fieldsValue => {
    // const rangeValue = fieldsValue['dateRange'];
    // this.setState({
    //   pagination: { ...this.state.pagination, page: 1 },
    //   uid: fieldsValue['uid'], start_date: rangeValue[0], end_date: rangeValue[1]
    // }, ()=>{
    //   this.getSMSLogs()      
    // });
  };

  onSubmitClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        pagination: { ...this.state.pagination, page: 1 },
        start_date: rangeValue[0], end_date: rangeValue[1]
      }, ()=>{
        this.getSurchargeStats()
      });
    }).catch(errorInfo => {
        
    });
  };
  
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getSurchargeSMSLogs()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }
  
  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Surcharge Report">
        <Card className="mb-2 card-body" bordered={false} >
          <Form
            ref={this.formRef}
            name="user_report"
            initialValues={{ dateRange: [this.state.start_date, this.state.end_date] }}
            onFinish={this.onHandleFinish}
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Date Range" name="dateRange" rules={VALIDATION_RULES['required']}>
                  <RangePicker style={{ width: '100%' }}  ranges={{
                      'Yesterday': [moment().subtract(1, 'day'), moment()],
                      '30 Days': [moment().subtract(1, 'month').add(1, 'day'), moment()],
                      '60 Days': [moment().subtract(2, 'month').add(1, 'day'), moment()],
                      '90 Days': [moment().subtract(3, 'month').add(1, 'day'), moment()],
                    }}
                   size='middle' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button loading={this.state.confirmLoading} 
                  className="ml-2" type="primary" 
                  htmlType="submit"
                  onClick={this.onSubmitClick}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {
          this.state.surcharge_stats.length > 0 &&
            <Table 
                className="mb-5"
                rowKey={record => record.day}
                columns={statsColumnSource} 
                dataSource={this.state.surcharge_stats} 
                pagination={{ 
                position: 'both',
                size: 'large',
                showQuickJumper: true,
                showSizeChanger: true,
                onChange:this.onShowSizeChange,
                onShowSizeChange: this.onShowSizeChange,
                total: this.state.pagination.total,
                showTotal: this.showTotal,
                defaultPageSize: this.state.pagination.pageSize,
                itemRender:this.itemRender,
                }}
                size="middle" 
                bordered 
            />
        }
      </Card>
    );
  }
}

BaseSurcharge.contextType = AppContext;
export default BaseSurcharge
