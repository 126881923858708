import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, message, Table, Button, Avatar } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { ordersAPI } from '../../../utility/API'
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangeBillingStatus from './ChangeBillingStatus'

class BaseBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billings: [],
      isStatusModalVisible: false,
      order_to_update: {},
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
  }

  GetColumn = () => {
    return [
      {
        title: '#',
        render: (text, record, index) => index + 1,
      },
      {
        title: "uid",
        render: (text, record) => record.uid,
      },
      {
        title: "Date",
        render: (text, record) => record.created,
      },
      {
        title: "Amount",
        render: (text, record) => record.amount,
      },
      {
        title: "Transaction ID",
        dataIndex: "transaction_id",
      },
      {
        title: "Modified at",
        render: (text, record) => record.modified,
      },
      {
        title: "Status",
        align: "center",
        render: (i, record) => (
          <>
            {record.status === 'Approved' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
              : <Avatar style={{ backgroundColor: 'grey' }} size="small"  />
            }
          </>
        )
      },
      {
        title: "Action",
        align: "center",
        render: (text, record) => (<>
          {record.status !== 'Approved' &&
          <Button onClick={() => { this.showChangeStatusModal(true, record); }}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </Button>
         }
        </>)
          
      }
    ]
  };

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Orders)
      this.context.selectMenu(MenuKeys.Orders);
    this.getAllOrder();

  }

  getAllOrder = () => {
    const response = ordersAPI().getAll(this.state.pagination.page, this.state.pagination.pageSize)
    response.then(response => {
      if (response.data.billings.length > 0) {
        this.setState({ 
          billings: response.data.billings,
          pagination: { ...this.state.pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
        });
      }
    }).catch(error => {
      if (error.response) {
        message.error('Request Failed');
      }
    });
  };

  showChangeStatusModal  = (is_visible, record, is_updated=false) => {
    this.setState({ isStatusModalVisible: is_visible, order_to_update: record});
    if(is_updated){
      this.getAllOrder(); 
    }
  }

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getAllOrder()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Order Details">
        {
          this.state.billings.length > 0 &&
          <>
            <ChangeBillingStatus visible={this.state.isStatusModalVisible} billing_order={this.state.order_to_update} onCancel={this.showChangeStatusModal}  />    
            <Table 
            className="mb-5"
            rowKey={record => record.bid} 
            columns={this.GetColumn()} 
            dataSource={this.state.billings} 
            pagination={{ 
              position: 'both',
              size: 'large',
              showSizeChanger: true,
              onChange:this.onShowSizeChange,
              onShowSizeChange: this.onShowSizeChange,
              total: this.state.pagination.total,
              defaultPageSize: this.state.pagination.pageSize,
              itemRender:this.itemRender,
              showTotal: this.showTotal,
              // pageSizeOptions: this.state.pagination.pageSizeOptions
            }}
            size="middle" 
            bordered />
          </>
        }
      </Card>
    );
  }
}

BaseBilling.contextType = AppContext;
export default BaseBilling
