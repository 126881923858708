import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, Button, message, Table, Avatar, Popconfirm } from "antd";
import AppContext from '../../AppContext';
import { mtroutesAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import CreateMTRouterModal from "./CreateMTRouter";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonGroup = Button.Group;

class BaseMTRouters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      mtrouters: [],
      isLoading: false,
      isModalVisible: false,
    };
  }

  GetColumn = () => {
    return [
      // {
      //   title: '#',
      //   width: '100px',
      //   render: (text, record, index) => index + 1,
      // },
      {
        title: "Type",
        dataIndex: 'type',
      },
      {
        title: "Order",
        dataIndex: 'order',
      },

      {
        title: "Rate",
        dataIndex: 'rate',
      },
      {
        title: "Connectors",
        dataIndex: 'connectors',
        render: (text, record) => JSON.stringify(record.connectors),
      },
      {
        title: "Filters",
        dataIndex: 'filters',
        render: (text, record) => JSON.stringify(record.filters),
      },
      // {
      //   title: "Status",
      //   align: "center",
      //   width: '150px',
      //   render: (i, record) => (
      //     <>
      //       {record.status === 'started' ? <Avatar style={{ backgroundColor: '#87d068' }} size="small" />
      //         : <Avatar style={{ backgroundColor: 'grey' }} size="small" />
      //       }
      //     </>
      //   )
      // },

      {
        title: "Action",
        align: "center",
        width: '250px',
        render: (text, record) => (
          <span>
            <ButtonGroup>
              <Button>
                <Popconfirm placement="left" title='Are you sure to delete this MTRouter?' onConfirm={() => {this.deleteConfirm(record.order)}} okText="Yes" cancelText="No">
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </Button>
            </ButtonGroup>
          </span>
        )
      }
    ]
  };

  deleteConfirm = (order) => {
    const response = mtroutesAPI().delete({order:order})
    response.then(response => {
      message.success(response.data.message);
      const dataSource = [...this.state.mtrouters];
      this.setState({ mtrouters: dataSource.filter(item => item.order !== order) });
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Delete Request Failed');
      }
    });    
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.MTRouter)
      this.context.selectMenu(MenuKeys.MTRouter);
    this.getAllMTRouters()

  }

  getAllMTRouters = () => {
    const response = mtroutesAPI().getAll()
    response.then(response => {
      if (response.data.mtrouters.length > 0) {
        this.setState({ mtrouters: response.data.mtrouters });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showModal = (is_visible, is_updated=false) => {
    this.setState({ isModalVisible: is_visible });
    if(is_updated){
      this.getAllMTRouters()
    }
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="MT Routers"
        extra={
          <Button className="theme-submit-btn mr-1" onClick={() => { this.showModal(true); }} title="add New Route">
            Add New
          </Button>
        }>
        { this.state.isModalVisible && <CreateMTRouterModal visible={this.state.isModalVisible} onCancel={this.showModal} /> }
        {
          this.state.mtrouters.length > 0 &&
          <Table 
            className="mb-5" 
            rowKey={record => record.order} columns={this.GetColumn()} 
            dataSource={this.state.mtrouters} 
            pagination={{ 
              position: 'both',
              size: 'large',
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: this.showTotal,
            }}
            bordered />
        }
      </Card>
    );
  }
}

BaseMTRouters.contextType = AppContext;
export default BaseMTRouters
