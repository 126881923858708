import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, message, Table } from "antd";
import AppContext from '../../AppContext';
import { userRoutesAPI } from '../../../utility/API'
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";


class BaseUserRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 'large',
      user_routes: [],
      isLoading: false,
    };
  }

  GetColumn = () => {
    return [
      {
        title: '#',
        width: '100px',
        render: (text, record, index) => index + 1,
      },
      {
        title: "Country",
        dataIndex: 'country',
      },
      {
        title: "Description",
        dataIndex: 'description',
      },
      {
        title: "Rate",
        dataIndex: 'rate',
      }
    ]
  };

 
  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Routes)
      this.context.selectMenu(MenuKeys.Routes, MenuKeys.MyNumbers);
    this.getAllUserRoutes()

  }

  getAllUserRoutes = () => {
    const response = userRoutesAPI().getUserRoutes()
    response.then(response => {
      if (response.data.user_routes.length > 0) {
        this.setState({ user_routes: response.data.user_routes });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  
  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="User Routes">
        {
          this.state.user_routes.length > 0 &&
          <Table rowKey={record => record.order} columns={this.GetColumn()} dataSource={this.state.user_routes} size="middle" bordered />
        }
      </Card>
    );
  }
}

BaseUserRoutes.contextType = AppContext;
export default BaseUserRoutes
