import React, { Component } from 'react';
import { Form, Input, message, Modal} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { VALIDATION_RULES } from '../../utility/constants';
import { usersAPI } from '../../utility/API'

class UpdatePassword extends Component {

  formRef = React.createRef();

  onHandleFinish = values => {
    const response = usersAPI().updateUserPassword(values)
    response.then(response => {
      message.success(response.data.message);
      this.props.onCancel(false, {}, true);
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });

  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
    // message.error(errorInfo);
    // if (response.errors && response.errors.new_password) {
    //   this.formRef.current.setFields({
    //     new_password: {
    //       value: this.state.new_password,
    //       errors: [new Error(response.errors.new_password)],
    //     },
    //   });
    // }
  };


  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} title="Change User Password" okText="Change" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={() => { this.formRef.current.submit(); }}>
        <Form
          ref={this.formRef}
          name="update_user_password"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical"
        >
          <Form.Item name="new_password" rules={VALIDATION_RULES['password']}>
          <Input prefix={<LockOutlined className="site-form-item-icon" icon="lock" />} type="password" placeholder="New Password" />
          </Form.Item>
        </Form>  
      </Modal>
    )
  }
}

export default UpdatePassword;