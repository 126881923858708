import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Select, Modal} from 'antd';
import { connectorsAPI } from '../../../utility/API'

const { Option } = Select;
class ChangeGroupStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false
    };
    this.formRef = React.createRef();
  }

  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = connectorsAPI().changeStatus(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({
          confirmLoading: false,
        });
        this.props.onCancel(false, {}, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
        }
        this.setState({
          confirmLoading: false,
        });
      });
    });

  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }

  onCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, onCancel } = this.props;
    let { connector } = this.props;
    return (
      <Modal visible={visible} confirmLoading={this.state.confirmLoading} title="Change Group Status" okText="Change" cancelText="Cancel" onCancel={() => {onCancel(false, {});}} onOk={this.onOk}>
        {Object.entries(connector).length !== 0 ?
          <Form
            initialValues={{ s: connector.status, cid: connector.cid }}
            ref={this.formRef}
            name="update_user_status"
            onFinish={this.onHandleFinish}
            onFinishFailed={this.onFinishFailed}
            layout="vertical"
          >
            <Form.Item label="Connector ID" name="cid" >
              <Input className="Input" defaultValue={connector.cid} readOnly={true} />
            </Form.Item>
            <Form.Item label="Status" name="s" rules={VALIDATION_RULES['required']}>
              <Select style={{ width: '100%' }} placeholder="Change status" >
                <Option key="restart">Restart</Option>
                <Option key="start">Start</Option>
                <Option key="stop">Stop</Option>
              </Select>
            </Form.Item>
          </Form>
          : null
        }
      </Modal>
    )
  }
}

export default ChangeGroupStatus