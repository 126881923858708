import React, { Component } from 'react'
import { logout } from "../../utility/API";
import { Redirect } from 'react-router-dom';
import AppContext from '../AppContext';
import { message } from "antd";

class Logout extends Component {
    static contextType = AppContext
  
    componentDidMount() {
      logout();
      let data = {
        is_auth: false,
        user_type: '',
        user: null
      }
      this.context.setInitialAuthFlag(data);
      message.success("Logout successful");
    }
  
    render() {
        return(<Redirect to='/login' />)
    }
}

export default Logout
