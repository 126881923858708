
export const headStyle = { fontSize:'1.514rem', lineHeight:1.5,  color: '#666' }
export const dateFormat = 'YYYY-MM-DD';
export const VALIDATION_RULES = {
    'vid': [{ required: true, message: "Vendor ID Required" }, { pattern: '^[a-zA-Z0-9_-]{3,32}$', message: 'The input is not valid!' }],
    'nick_name': [{ required: true, message: "Nick Name Required" }, { pattern: '^.{3,24}$', message: 'The input is not valid!' }],
    'gid': [{ required: true, message: "GID Name  Required" }, { pattern: '^[a-zA-Z0-9_-]{3,15}$', message: 'The input is not valid!' }],
    'cid': [{ required: true, message: "CID Name  Required" }, { pattern: '^[a-zA-Z0-9_-]{3,20}$', message: 'The input is not valid!' }],
    'uid': [{ required: true, message: "UID Name  Required" }, { pattern: '^[a-zA-Z0-9_-]{3,20}$', message: 'The input is not valid!' }],
    'username': [{ required: true, message: "Username  Required" }, { pattern: '^[a-zA-Z0-9_-]{3,20}$', message: 'The input is not valid!' }],
    'password': [{ required: true, message: "Password  Required" }, { pattern: '^.{4,8}$', message: 'The input is not valid!' }],
    'fid': [{ required: true, message: "FID Name  Required" }, { pattern: '^[a-zA-Z0-9_-]{3,16}$', message: 'The input is not valid!' }],
    'host': [{ required: true, message: "Host Name  Required" }, { pattern: '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$', message: 'The input is not valid!' }],
    'port': [{ required: true, message: "Port No  Required" }, { pattern: '^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$', message: 'The input is not valid!' }],
    'required': [{ required: true, message: "Input  Required" }],
    'email': [{ required: true, message: "Email  Required" }, { type: 'email', message: 'The input is not valid!'}],
    'non-required-password': [{ pattern: '^.{5,8}$', message: 'The input is not valid!' }],
    
}

export const MenuKeys = {
    'Profile': '1', 
    'Dashboard': '2',
    'Users': '3', 
    'Groups': '4', 
    'Filters': '5', 
    'MTRouter': '6', 
    'Billing': '3',
    'MyNumbers': 'MyNumbers',
    'Routes': 'Routes',
    'BuyRoutes': '4',
    'Reports': '7',
    'Sub1': 'sub1',
    'Settings': 'Settings',
    'DID': 'DID',
    'PasswordUpdate': 'PasswordUpdate',
    'UserReport': 'UserReport',
    'VendorReport': 'VendorReport',
    'Surcharge': 'Surcharge',
    'SurchargeReport': 'SurchargeReport',
    'UserSub1': 'UserSub1',
    'UserSMSLogReport': 'UserSMSLogReport',
    'UserSurchargeReport': 'UserSurchargeReport',
    'Orders': '8',
    'Vendors':'9', 
    'Connectors':'Connectors',     
}

export const FILTER_TYPES = [
    "TransparentFilter",
    "ConnectorFilter",
    "UserFilter",
    "GroupFilter",
    "SourceAddrFilter",
    "DestinationAddrFilter",
    "ShortMessageFilter",
    "DateIntervalFilter",
    "TimeIntervalFilter",
    "TagFilter",
    "EvalPyFilter"
]


export const MTROUTE_TYPES = [
    "DefaultRoute",
    "StaticMTRoute",
    "RandomRoundrobinMTRoute"
]

export const USER_TYPE = { 
    Administrator: 'Administrator',
    User: 'User', 
}


export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
