import React, { Component } from 'react';
import moment from 'moment';
import "antd/dist/antd.css";
import { Card, message, Table, Form, Button, DatePicker, Select, Row, Col } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys, VALIDATION_RULES, INDEX_PAGE_SIZE_DEFAULT, INDEX_PAGE_SIZE_OPTIONS } from "../../../utility/constants";
import { usersAPI, vendorsAPI } from '../../../utility/API'

const { Option } = Select;
const { RangePicker } = DatePicker;

const getVendorOptions = (dataSource) => {
  let children = [<Option key="All">ALL</Option>];
  for (let index = 0; index < dataSource.length; index++) {
    children.push(<Option key={dataSource[index].vid}>{dataSource[index].nick_name}</Option>);
  }
  return children;
};


const columnSource = [
  {
    title: '#',
    render: (text, record, index) => index + 1,
  },
  {
    title: "msgid",
    dataIndex: "msgid"
  },
  {
    title: "Created",
    dataIndex: "created_at"
  },
  {
    title: "To Number",
    dataIndex: "destination_addr"
  },
  {
    title: "From Number",
    dataIndex: "source_addr"
  },
  {
    title: "Rate",
    dataIndex: "rate"
  },
  {
    title: "Status",
    render: (text, record, index) => record.status === 'ESME_ROK' ? 'Delivered' : 'Undelivered'
  },
  {
    title: "Status At",
    dataIndex: "status_at"
  },
 
]

class BaseReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sms_log: [],
      vendors: [],
      vid: 'All',
      start_date: moment().subtract(1, 'day'),
      end_date: moment(),
      confirmLoading: false,
      loading: false,
      progress: 0,
      pagination: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      }
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.UserSMSLogReport)
      this.context.selectMenu(MenuKeys.UserSMSLogReport, MenuKeys.UserSub1);
    
    
    // this.getSMSLogDetails();
    this.getAllVendors();
  }

  getAllVendors = () => {
    const response = vendorsAPI().getAll()
    response.then(response => {
      if (response.data.vendors.length > 0) {
        this.setState({ vendors: response.data.vendors, mounted: true });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  getSMSLogDetails = () => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const values = {
        'uid': this.context.user.uid,
        'vid': this.state.vid,
        'start_date': this.state.start_date.format('YYYY-MM-DD'),
        'end_date': this.state.end_date.format('YYYY-MM-DD'),
      }
      // console.log(values)
      const response = usersAPI().getUserLog(values, this.state.pagination.page, this.state.pagination.pageSize)
      response.then(response => {
        if (response.data.sms_log.length > 0) {
          // console.log(response.data)
          this.setState({ 
            sms_log: response.data.sms_log,
            pagination: { ...this.state.pagination,  pageTotal: response.data.total_pages, total: response.data.total_records }, 
            confirmLoading: false
          });
        } else {
          message.error('No records found!');
          this.setState({ sms_log: [], confirmLoading: false });
        }
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          message.error('Request Failed');
          this.setState({ sms_log: [], confirmLoading: false });
        }
      });
    });
  };

  onHandleFinish = (fieldsValue) => {
  };

  onSubmitClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        pagination: { ...this.state.pagination, page: 1 },
        vid: values['vid'], start_date: rangeValue[0], end_date: rangeValue[1]
      }, ()=>{
        this.getSMSLogDetails()
      });
    }).catch(errorInfo => {
        
    });
  };
  
  onCSVDownloadClick = () => {
    this.formRef.current.validateFields().then(values => {
      const rangeValue = values['dateRange'];
      this.setState({
        vid: values['vid'], start_date: rangeValue[0], end_date: rangeValue[1], loading:true, progress:0
      }, ()=>{
        this.onCSVDownload()
      });
    }).catch(errorInfo => {
        
    });
  };


  onCSVDownloadProgress = (progressEvent) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.setState({ progress: percentCompleted });
}

  onCSVDownload = () => {
    const values = {
      'uid': this.context.user.uid,
      'vid': this.state.vid,
      'start_date': this.state.start_date.format('YYYY-MM-DD'),
      'end_date': this.state.end_date.format('YYYY-MM-DD'),
    }
    const response = usersAPI().getUserLogCSV(values, this.onCSVDownloadProgress)
    response.then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.context.user.uid}_${this.state.start_date.format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    }).catch(error => {
      if (error.response) {
        message.error('No records found!');
        this.setState({ loading: false });
        // console.log(error.response)
      }
    });   
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pagination: { ...this.state.pagination, page: current, pageSize: pageSize },             
    }, ()=>{
      this.getSMSLogDetails()
    });
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button className="mr-1" title="Previous">Previous</Button>;
    }
    if (type === 'next') {
      return <Button className="ml-1" title="Next">Next</Button>;
    }
    return originalElement;
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} items`;
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Outbound Report">
        <Card className="mb-2 card-body" bordered={false} >
        <Form
            ref={this.formRef}
            name="user_report"
            initialValues={{ vid: this.state.vid, dateRange: [this.state.start_date, this.state.end_date] }}
            onFinish={this.onHandleFinish}
          >
            <Row gutter={12} justify="start">
              <Col span={4}>
                <Form.Item label="Vendors" name="vid" rules={VALIDATION_RULES['required']}>
                  <Select style={{ width: '100%' }} placeholder="Select Vendor" >
                    {getVendorOptions(this.state.vendors)}
                  </Select>
                </Form.Item>  
              </Col>
              <Col span={6}>
                <Form.Item label="Date Range" name="dateRange" rules={VALIDATION_RULES['required']}>
                  <RangePicker style={{ width: '100%' }}  ranges={{
                    'Yesterday': [moment().subtract(1, 'day'), moment()],
                    '30 Days': [moment().subtract(1, 'month').add(1, 'day'), moment()],
                    '60 Days': [moment().subtract(2, 'month').add(1, 'day'), moment()],
                    '90 Days': [moment().subtract(3, 'month').add(1, 'day'), moment()],
                    }}
                    size='middle' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button loading={this.state.confirmLoading}  
                  className="ml-2" type="primary" 
                  htmlType="submit"
                  onClick={this.onSubmitClick}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Form>
        </Card>
        {
          this.state.sms_log.length > 0 &&
          <Table 
          className="mb-5" 
          rowKey={record => record.msgid} 
          columns={columnSource} 
          dataSource={this.state.sms_log} 
          pagination={{ 
            size: 'large',
            showQuickJumper: true,
            showSizeChanger: true,
            onChange:this.onShowSizeChange,
            onShowSizeChange: this.onShowSizeChange,
            total: this.state.pagination.total,
            showTotal: this.showTotal,
            defaultPageSize: this.state.pagination.pageSize,
            itemRender:this.itemRender,
          }}
          size="middle" bordered />
        }
      </Card>
    );
  }
}

BaseReports.contextType = AppContext;
export default BaseReports
