import React, { Component } from 'react'
import BaseUsers from './BaseUsers'
import { MenuKeys } from "../../../utility/constants";
import AppContext from '../../AppContext';


class BaseUserContainer extends Component {

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Users) {
      this.context.selectMenu(MenuKeys.Users);
    }
  }
  render() {
    return (
        <BaseUsers />
    )
  }
}


BaseUserContainer.contextType = AppContext;
export default BaseUserContainer
