import React, { Component } from 'react';
import { message, Modal, Table } from 'antd';
import { vendorsAPI } from '../../../utility/API'


const dataColumn = [
  {
    title: '#',
    render: (text, record, index) => index + 1,
  },
  {
    title: "UUID",
    dataIndex: "uuid"
  },
  {
    title: "Country Code",
    dataIndex: "country_code"
  },
  {
    title: "Rate",
    dataIndex: 'rate',
  },

]


class ListRateCardModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rate_cards: []
    };
  }

  getVendorsRateCards = (vid) => {
    const response = vendorsAPI().getRateCardByVID({ vid: vid })
    response.then(response => {
      if (response.data.rate_cards.length > 0) {
        this.setState({ rate_cards: response.data.rate_cards });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  render() {
    const { visible, onCancel, vendor } = this.props;
    return (
      <Modal visible={visible} cancelButtonProps={{ style: { display: 'none' } }} confirmLoading={this.state.confirmLoading} title="Rate Cards" okText="OK" cancelText="Cancel" onCancel={() => { onCancel(false, {}); }} onOk={() => { onCancel(false, {}); }}>
        {this.state.rate_cards.length === 0 && vendor && this.getVendorsRateCards(vendor.vid)}
        {
          this.state.rate_cards.length > 0 &&
          <Table rowKey={record => record.uuid} columns={dataColumn} dataSource={this.state.rate_cards} size="middle" bordered />
        }
      </Modal>
    )
  }
}

export default ListRateCardModal