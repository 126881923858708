import React, { Component } from 'react';
import "antd/dist/antd.css";
import { Card, message, Table, Button } from "antd";
import AppContext from '../../AppContext';
import { customStyle } from '../../../customStyle';
import { MenuKeys } from "../../../utility/constants";
import { usersAPI } from '../../../utility/API'
import AddFund from '../dashboard/AddFund'


class BaseBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billing: [],
      user: {},
      isAddFundModalVisible: false
    };
  }

  GetColumn = () => {
    return [
      {
        title: '#',
        render: (text, record, index) => index + 1,
      },
      {
        title: "Date",
        render: (text, record, index) => record.created,
      },
      {
        title: "Amount",
        render: (text, record, index) => record.amount,
      },
      {
        title: "Transaction ID",
        dataIndex: "transaction_id",
      },
      {
        title: "Status",
        render: (text, record, index) => record.status,
      }
    ]
  };

  componentDidMount() {
    if (this.context.menuKey !== MenuKeys.Billing)
      this.context.selectMenu(MenuKeys.Billing);
    this.getBillingDetails();

  }

  getBillingDetails = () => {
    const response = usersAPI().getBillingDetails()
    response.then(response => {
      if (response.data.billing.length > 0) {
        this.setState({ billing: response.data.billing });
      }
    }).catch(error => {
      if (error.response) {
        // console.log(error.response)
        message.error('Request Failed');
      }
    });
  };

  showAddFundModal  = (is_visible, is_updated=false) => {
    this.setState({ isAddFundModalVisible: is_visible});
    if(is_updated){
      this.getBillingDetails(); 
    }
  }

  render() {
    return (
      <Card className="custom-card" bordered={false} headStyle={customStyle.headStyle} title="Billing"
      extra={ 
        <Button type="primary" className="mr-2" onClick={() => {this.showAddFundModal(true); }}>Add Fund</Button>
      }>
        <AddFund visible={this.state.isAddFundModalVisible} user={this.context.user} onCancel={this.showAddFundModal}  />
        {
          this.state.billing.length > 0 &&
          <Table rowKey={record => record.bid} columns={this.GetColumn()} dataSource={this.state.billing} size="middle" bordered />
        }
      </Card>
    );
  }
}

BaseBilling.contextType = AppContext;
export default BaseBilling
