// utils/API.js

import axios from "axios";
// Auth API
export var COMMON_BASE_URL = 'https://service.inboxed.co/api';
// export var COMMON_BASE_URL = 'http://159.89.137.110/api';
export var LOGIN_URL = COMMON_BASE_URL + '/api-token-auth';
export var METADATA_URL = COMMON_BASE_URL + '/administration/metadata/';

export var VENDORS_URL = COMMON_BASE_URL + '/administration/vendors/manage/';
export var DID_URL = COMMON_BASE_URL + '/administration/did/manage/';
export var ADMIN_USERS_URL = COMMON_BASE_URL + '/administration/users/manage/';
export var GROUPS_URL = COMMON_BASE_URL + '/administration/groups/manage/';
export var SMPPCONNECTOR_URL = COMMON_BASE_URL + '/administration/smppccm/manage/';
export var FILTERS_URL = COMMON_BASE_URL + '/administration/filters/manage/';
export var MTROUTERS_URL = COMMON_BASE_URL + '/administration/mtrouter/manage/';
export var ORDERS_URL = COMMON_BASE_URL + '/administration/orders/manage/';
export var ADMIN_SMS_LOG_URL = COMMON_BASE_URL + '/administration/smslog/manage/';
export var SURCHARGE_RATE_URL = COMMON_BASE_URL + '/administration/surcharge/manage/';


export var USERS_URL = COMMON_BASE_URL + '/account/user/manage/';
export var SMS_LOG_URL = COMMON_BASE_URL + '/account/smslog/manage/';
export var USER_DID_URL = COMMON_BASE_URL + '/account/did/manage/';
export var USER_ROUTES_URL = COMMON_BASE_URL + '/account/routes/manage/';

export const usersAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getUser: () => axios.get(USERS_URL, axiosConfig),
      addFund: (values) =>  axios.post(USERS_URL, { ...values, 's': "recharge" }, axiosConfig),
      getBillingDetails:() => axios.post(USERS_URL, { 's': "get_billing" }, axiosConfig),
      updateUserPassword:(values) => axios.post(USERS_URL, { ...values, 's': "change_password" }, axiosConfig),
      sendTestSMS:(values) => axios.post(USERS_URL, { ...values, 's': "send-test-sms" }, axiosConfig),
    //   getUserLog: () => axios.get(SMS_LOG_URL, axiosConfig),
      getUserLog: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${SMS_LOG_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, values, axiosConfig),
      getUserLogCSV: (values, onProgress) =>  axios.post(
          SMS_LOG_URL, 
          { ...values, 's': "csv-download" }, 
          { ...axiosConfig, responseType: 'stream', onDownloadProgress: onProgress }),
          
    }
}


export const userRoutesAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getUserRoutes: () => axios.get(USER_ROUTES_URL, axiosConfig),
      getUserRoutesById: (values) => axios.post(USER_ROUTES_URL,  { ...values, 's': "list" }, axiosConfig),
    }

}

export const ordersAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
        getAll: (PAGE_INDEX=1, PAGE_SIZE=10) => axios.get(`${ORDERS_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, axiosConfig),
        create: (values) =>  axios.post(ORDERS_URL, { ...values, 's': "add" }, axiosConfig),
        changeStatus: (values) =>  axios.post(ORDERS_URL, values, axiosConfig),
        delete: (values) =>  axios.post(ORDERS_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}

export const statsAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
        getSMSLog: () => axios.get(ADMIN_SMS_LOG_URL, axiosConfig),
        getSMSLogByUser: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${ADMIN_SMS_LOG_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "users" }, axiosConfig),
        getSMSLogByVender: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${ADMIN_SMS_LOG_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "venders" }, axiosConfig),
        getSMSLogCSV: (values, onProgress) =>  axios.post(
            ADMIN_SMS_LOG_URL, 
            { ...values, 's': "csv-download" }, 
            { 
                ...axiosConfig, 
                responseType: 'stream',
                onDownloadProgress: onProgress 
            }),
        getSurchargeLogDetails: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${ADMIN_SMS_LOG_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "surcharge" }, axiosConfig),
        getSurchargeStats: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${ADMIN_SMS_LOG_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "surcharge-stats" }, axiosConfig),
        
    }
}


export const groupsAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(GROUPS_URL, axiosConfig),
      update: (values) =>  axios.post(GROUPS_URL, { ...values, 's': "edit" }, axiosConfig),
      create: (values) =>  axios.post(GROUPS_URL, { ...values, 's': "add" }, axiosConfig),
      changeStatus: (values) =>  axios.post(GROUPS_URL, values, axiosConfig),
      delete: (values) =>  axios.post(GROUPS_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}

export const adminUsersAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      login: (values) => axios.post(LOGIN_URL, values),
      getAll: () => axios.get(ADMIN_USERS_URL, axiosConfig),
      update: (values) =>  axios.post(COMMON_BASE_URL+ '/administration/edituser/', values, axiosConfig),
      create: (values) =>  axios.post(COMMON_BASE_URL+ '/administration/adduser/', values, axiosConfig),
      changeStatus: (values) =>  axios.post(ADMIN_USERS_URL, values, axiosConfig),
      delete: (values) =>  axios.post(ADMIN_USERS_URL, { ...values, 's': "delete" }, axiosConfig),
      updateBalance: (values) =>  axios.post(ADMIN_USERS_URL, { ...values, 's': "recharge" }, axiosConfig),
      updateRate: (values) =>  axios.post(ADMIN_USERS_URL, { ...values, 's': "rate_change" }, axiosConfig),
    }
}

export const metadataAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getCountryCodes: () => axios.get(METADATA_URL+'country-code/', axiosConfig),
    }
}

export const vendorsAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(VENDORS_URL, axiosConfig),
      addRateCard: (values) =>  axios.post(VENDORS_URL, { ...values, 's': "rate-card" }, axiosConfig),
      getRateCards: () =>  axios.post(VENDORS_URL, { 's': "list-rate-card" }, axiosConfig),
      getRateCardByVID: (values) =>  axios.post(VENDORS_URL, { ...values, 's': "list-rate-card" }, axiosConfig),
      delete: (values) =>  axios.post(VENDORS_URL, { ...values, 's': "delete" }, axiosConfig),
      
    }
}

export const DID_API = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(DID_URL, axiosConfig),
      uploadDID: (values) =>  axios.post(DID_URL, values, axiosConfig),
      assignDIDtoUser: (values) =>  axios.post(DID_URL, values, axiosConfig),
      assignURL: (values) =>  axios.post(DID_URL, { ...values, 's': "assign-url" }, axiosConfig),
      deleteDIDByCSV: (values) =>  axios.post(DID_URL, values, axiosConfig),
      getDIDsByVender: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${DID_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "vendors" }, axiosConfig),
      getDIDsByUser: (values, PAGE_INDEX=1, PAGE_SIZE=10) =>  axios.post(`${USER_DID_URL}?PAGE_INDEX=${PAGE_INDEX}&PAGE_SIZE=${PAGE_SIZE}`, { ...values, 's': "list" }, axiosConfig),
    }
}

export const SurchargeRateAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(SURCHARGE_RATE_URL, axiosConfig),
      update: (values) =>  axios.post(SURCHARGE_RATE_URL, { ...values, 's': "edit" }, axiosConfig),
      create: (values) =>  axios.post(SURCHARGE_RATE_URL, { ...values, 's': "add" }, axiosConfig),
      delete: (values) =>  axios.post(SURCHARGE_RATE_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}

export const connectorsAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(SMPPCONNECTOR_URL, axiosConfig),
      update: (values) =>  axios.post(SMPPCONNECTOR_URL, { ...values, 's': "edit" }, axiosConfig),
      create: (values) =>  axios.post(SMPPCONNECTOR_URL, { ...values, 's': "add" }, axiosConfig),
      getConnectorRoutes: () =>  axios.post(SMPPCONNECTOR_URL, { 's': "get-routes" }, axiosConfig),
      changeStatus: (values) =>  axios.post(SMPPCONNECTOR_URL, values, axiosConfig),
      delete: (values) =>  axios.post(SMPPCONNECTOR_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}



export const filtersAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.get(FILTERS_URL, axiosConfig),
      create: (values) =>  axios.post(FILTERS_URL, { ...values, 's': "add" }, axiosConfig),
      delete: (values) =>  axios.post(FILTERS_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}


export const mtroutesAPI = () => {
    let token = get_cookie('Authorization');
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    };
    return {
      getAll: () => axios.post(MTROUTERS_URL, { 's': "list" }, axiosConfig),
      create: (values) =>  axios.post(MTROUTERS_URL, { ...values, 's': "add" }, axiosConfig),
      delete: (values) =>  axios.post(MTROUTERS_URL, { ...values, 's': "delete" }, axiosConfig),
    }
}

export function isAuthenticated() {
    var auth_info = get_cookie('Authorization')
    if(auth_info){
        return true;
    }    
    return false;
}

export function getUserType() {
    var user_type = get_cookie('UserType')
    if(user_type){
        return user_type;
    }    
    return false;
}

export function getUser() {
    const user = JSON.parse(get_cookie('user'));
    if(user){
        return user;
    }    
    return false;
}

export function logout() {
    delete_cookie('Authorization');
    delete_cookie('UserType');
    // alert("Logged out");
}

export function delete_cookie(name){
    return window.localStorage.removeItem(name);
}

export function get_cookie(name){
    return window.localStorage.getItem(name);
}

export function set_cookie(name, value) {
    return window.localStorage.setItem(name, value);
}
