import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandScissors, faUserCircle, faWrench, faSignOutAlt, faCalculator, faPaperPlane, faTachometerAlt, faChartBar, faPhone, faMobile, faKey, 
  faPlug, faRoute, faFilter, faLayerGroup, faTable } from '@fortawesome/free-solid-svg-icons'
import { USER_TYPE } from '../../utility/constants';
import AppContext from '../AppContext';
import UpdatePassword from '../auth/UpdatePassword';
import SendTestSMS from '../UserPanel/dashboard/SendTestSMS';
import logoFile from '../../images/logo.png'


const { SubMenu } = Menu;

class LeftPanelAdmin extends Component {
  static contextType = AppContext
  
  render() {
    let isSubMenuKey = {}
    if(this.context.subMenuKey){
      isSubMenuKey = {
        openKeys: [this.context.subMenuKey]
      }
    }
    return (
      <Menu style={{ background: '#FEFEFE', width: 256 }}
          className="leftMenu"
          {...isSubMenuKey}
          selectedKeys={[this.context.menuKey]}
          mode="inline"
        >
          <div className="logo-container">
            <img className='logo-img' src={logoFile} alt="SMS Panel" />
          </div>
          <Menu.Item key="2" className="leftMenuItem sweep-to-right" title="Dashboard" id="dashboard">
            <Link to="/dashboard">
              <FontAwesomeIcon icon={faTachometerAlt} />
              <span className="ml-1">Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="9" className="leftMenuItem sweep-to-right" title="Vendors" id="vendors">
            <Link to="/vendors">
              <FontAwesomeIcon icon={faUserCircle} />
              <span className="ml-1">Vendors</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="Connectors" className="leftMenuItem sweep-to-right" title="Connectors" id="connectors">
            <Link to="/connectors">
              <FontAwesomeIcon icon={faPlug} />
              <span className="ml-1">Connectors</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="DID" className="leftMenuItem sweep-to-right" title="DID" id="did">
            <Link to="/did">
              <FontAwesomeIcon icon={faMobile} />
              <span className="ml-1">DID</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3" className="leftMenuItem sweep-to-right" title="Users" id="users">
            <FontAwesomeIcon icon={faUserCircle} />
            <span className="ml-1">Users</span>
            <Link to="/users" />
          </Menu.Item>
          <Menu.Item key="4" className="leftMenuItem sweep-to-right" title="Groups" id="groups">
            <FontAwesomeIcon icon={faLayerGroup} />
            <span className="ml-1">Groups</span>
            <Link to="/groups" />
          </Menu.Item>
          <Menu.Item key="5" className="leftMenuItem sweep-to-right" title="Filters" id="filters">
            <FontAwesomeIcon icon={faFilter} />
            <span className="ml-1">Filters</span>
            <Link to="/filters" />
          </Menu.Item>
          <Menu.Item key="6" className="leftMenuItem sweep-to-right" title="MTRouter" id="mtrouter">
            <FontAwesomeIcon icon={faRoute} />
            <span className="ml-1">MTRouter</span>
            <Link to="/mtrouter" />
          </Menu.Item>
          <Menu.Item key="Surcharge" className="leftMenuItem sweep-to-right" title="Surcharge Rate" id="surcharge">
            <FontAwesomeIcon icon={faHandScissors} />
            <span className="ml-1">Surcharge Rate</span>
            <Link to="/surcharge" />
          </Menu.Item>
          <SubMenu key="sub1"
            className="sweep-to-right" 
            title={
              <span>
                <FontAwesomeIcon icon={faChartBar} />
                <span className="ml-1">Reports</span>
              </span>
            }
            id="sub_reports"
            >
            <Menu.Item key="UserReport" className="leftMenuItem sweep-to-right" title="User Report" id="user_reports">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">User Reports</span>
              <Link to="/users/reports" />
            </Menu.Item>
            <Menu.Item key="VendorReport" className="leftMenuItem sweep-to-right" title="Vendor Report" id="vendor_reports">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">Vendor Report</span>
              <Link to="/vendors/reports" />
            </Menu.Item>
            <Menu.Item key="SurchargeReport" className="leftMenuItem sweep-to-right" title="Surcharge Report" id="surcharge_report">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">Surcharge Report</span>
              <Link to="/surcharge/reports" />
            </Menu.Item>
            
          </SubMenu>
          <Menu.Item key="8" className="leftMenuItem sweep-to-right" title="Order Details" id="bill_details">
            <FontAwesomeIcon icon={faCalculator} />
            <span className="ml-1">Order Details</span>
            <Link to="/orders" />
          </Menu.Item>
          <Menu.Item key="10" className="leftMenuItem sweep-to-right">
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span className="ml-1">Logout</span>
            <Link to="/logout" />
          </Menu.Item>
        </Menu>
    );
  }
}

class LeftPanelUser extends Component {
  static contextType = AppContext
  
  constructor(props) {
    super(props);
    this.state = {
      isUpdatePasswordModalVisible: false,
      isSendTestSMSModalVisible: false
    };
  }
  
  showUpdatePassworModal  = (is_visible, is_updated=false) => {
    this.setState({ isUpdatePasswordModalVisible: is_visible,});
  }

  showSendSMSModal  = (is_visible, is_updated=false) => {
    this.setState({ isSendTestSMSModalVisible: is_visible,});
  }



  render() {
    let isSubMenuKey = {}
    if(this.context.subMenuKey){
      isSubMenuKey = {
        openKeys: [this.context.subMenuKey]
      }
    }
    return (
      <Menu style={{ background: '#FEFEFE', width: 256 }}
          className="leftMenu"
          {...isSubMenuKey}
          selectedKeys={[this.context.menuKey]}
          mode="inline"
        >
          <div className="logo-container">
            <img className='logo-img' src={logoFile} alt="SMS Panel" />
          </div>
          <Menu.Item key="2" className="leftMenuItem sweep-to-right" title="User Dashboard" id="dashboard">
            <Link to="/account/dashboard">
              <FontAwesomeIcon icon={faTachometerAlt} />
              <span className="ml-1">Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3" className="leftMenuItem sweep-to-right" title="Billing Details" id="bill_details">
            <FontAwesomeIcon icon={faCalculator} />
            <span className="ml-1">Billing Details</span>
            <Link to="/account/billing" />
          </Menu.Item>
          
          <SubMenu key="MyNumbers"
            className="sweep-to-right" 
            title={
              <span>
                <FontAwesomeIcon icon={faPhone} />
                <span className="ml-1">My Numbers</span>
              </span>
            }
            id="my_numbers"
            
            >
            <Menu.Item key="Routes" className="leftMenuItem sweep-to-right" title="My Routes" id="my_routes">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">My Routes</span>
              <Link to="/account/routes" />
            </Menu.Item>
            <Menu.Item key="DID" className="leftMenuItem sweep-to-right" title="DID Number" id="did_number">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">DID Number</span>
              <Link to="/account/did" />
            </Menu.Item>
          </SubMenu>

          {/* <Menu.Item key="4" className="leftMenuItem sweep-to-right" title="Buy Routes" id="buy_routes">
            <FontAwesomeIcon icon={faUserCircle} />
            <span className="ml-1">Routes</span>
            <Link to="/account/routes" />
          </Menu.Item> */}
          <Menu.Item key="SendSMS" 
            onClick={() => { this.showSendSMSModal(true); }}
            className="leftMenuItem sweep-to-right" title="Send Test SMS" id="send_test_sms">
            <FontAwesomeIcon icon={faPaperPlane} />
            <span className="ml-1">Send Test SMS</span>
          </Menu.Item>
          <SubMenu key="UserSub1"
            className="sweep-to-right" 
            title={
              <span>
                <FontAwesomeIcon icon={faChartBar} />
                <span className="ml-1">Reports</span>
              </span>
            }
            id="user_sub_1"
            >
            <Menu.Item key="UserSMSLogReport" className="leftMenuItem sweep-to-right" title="User Report" id="user_reports">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">SMS Logs</span>
              <Link to="/account/reports" />
            </Menu.Item>
            <Menu.Item key="UserSurchargeReport" className="leftMenuItem sweep-to-right" title="Surcharge Report" id="surcharge_report">
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">Surcharge Report</span>
              <Link to="/account/surcharge" />
            </Menu.Item>
            
          </SubMenu>

          <SubMenu key="Settings"
            className="sweep-to-right" 
            title={
              <span>
                <FontAwesomeIcon icon={faWrench} />
                <span className="ml-1">Settings</span>
              </span>
            }
            id="Settings"
            
            >
            <Menu.Item key="PasswordUpdate" 
            onClick={() => { this.showUpdatePassworModal(true); }}
            className="leftMenuItem sweep-to-right" title="Change Password" id="pwd_change">
              <FontAwesomeIcon icon={faKey} />
              <span className="ml-1">Update Password</span>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="10" className="leftMenuItem sweep-to-right">
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span className="ml-1">Logout</span>
            <Link to="/logout" />
          </Menu.Item>
          <UpdatePassword visible={this.state.isUpdatePasswordModalVisible} onCancel={this.showUpdatePassworModal}  />    
          <SendTestSMS visible={this.state.isSendTestSMSModalVisible} onCancel={this.showSendSMSModal}  />
                
        </Menu>
    );
  }
}


class LeftPanel extends Component {
  static contextType = AppContext
  render() {
    return (
      <div>
        { this.context.user_type === USER_TYPE.Administrator ? <LeftPanelAdmin /> : <LeftPanelUser />}
      </div>
    )
  }
}


export default LeftPanel;
