import React, { Component } from 'react';
import { VALIDATION_RULES } from '../../../utility/constants';
import { Form, Input, message, Modal } from 'antd';
import { SurchargeRateAPI } from '../../../utility/API'

class AddSurchargeRateModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false
      
    };
    this.formRef = React.createRef();
  }
  onHandleFinish = values => {
    this.setState({
      confirmLoading: true,
    }, ()=>{
      const response = SurchargeRateAPI().create(values)
      response.then(response => {
        message.success(response.data.message);
        this.setState({confirmLoading: false,});
        this.props.onCancel(false, true);
      }).catch(error => {
        if (error.response) {
          // console.log(error.response)
          this.setState({confirmLoading: false,});
          message.error('Request Failed');
        }
      });
    });  
  };

  onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  onOk = () => {
    this.formRef.current.submit();
  }
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} destroyOnClose={true} confirmLoading={this.state.confirmLoading} title="Add New Surcharge Rate" okText="Create" cancelText="Cancel" onCancel={() => { onCancel(false); }} onOk={this.onOk}>
        <Form
          ref={this.formRef}
          name="login"
          onFinish={this.onHandleFinish}
          onFinishFailed={this.onFinishFailed}
          layout="vertical" 
        >
          <Form.Item label="Carrier Name" name="carrier_name" >
              <Input className="Input"  placeholder="Enter carrier name here" rules={VALIDATION_RULES['required']} />
            </Form.Item>
            <Form.Item label="Rate" name="surcharge_rate" rules={VALIDATION_RULES['required']}>
              <Input className="Input" placeholder="Enter surcharge rate here" />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default AddSurchargeRateModal;
